import {useTranslation} from "react-i18next";
import PageBackground from "../components/PageBackground";
import {Button, TextLinkButton} from "../components/Button";
import SectionTitle from "../components/SectionTitle";
import PageSection from "../components/PageSection";
import Quote from "../components/Quote";
import PartnerLogos from "../components/PartnerLogos";
import HeroContent from "../components/HeroContent";
import Markdown from 'react-markdown'
import {
    DocsEdgeCloudServicesAI, DocsEdgeCloudServicesRendering, DocsEdgeCloudServicesVideo,
    DocsHomeUrl, DocsSmartContractsUrl,
    DocsThetaVideoAPIUrl, DocsTNT20TutorialUrl, EdgeCloudUrl, GithubUrl, WhitepapersMainnet4Url,
} from "../constants";

import devsBackground from '../assets/backgrounds/devs.jpg';
import quoteLogosReplay from '../assets/logos/quotes/replay@2x.png';
import quoteLogosOpenTheta from "../assets/logos/quotes/opentheta@2x.png";
import quoteLogosPassaways from "../assets/logos/quotes/passaways@2x.png";
import mn4Image from '../assets/images/metachain/mn4@2x.png';
import edgeCloudImage from '../assets/images/hero/edgecloud@2x.png';

import pLogosLine from '../assets/logos/developer-partners/line@2x.png';
import pLogosKlaytn from '../assets/logos/developer-partners/klaytn@2x.png';
import pLogosChainlink from '../assets/logos/developer-partners/chainlink@2x.png';
import pLogosReplay from '../assets/logos/developer-partners/replay@2x.png';
import pLogosMetapass from '../assets/logos/developer-partners/metapass@2x.png';
import pLogosOpentheta from '../assets/logos/developer-partners/opentheta@2x.png';
import pLogosPassaways from '../assets/logos/developer-partners/passaways@2x.png';
import pLogosDecentraland from '../assets/logos/developer-partners/decentraland@2x.png';
import pLogosUltra from '../assets/logos/developer-partners/ultra@2x.png';
import Quotes from "../components/Quotes";

const DeveloperCard = ({title, subtitle, cta}) => {
    return (
        <div className={'DeveloperCard'}>
            <div className={'DeveloperCard__title'}>
                {title}
            </div>
            <div className={'DeveloperCard__subtitle'}>
                {subtitle}
            </div>
            {
                cta &&
                <TextLinkButton className={'DeveloperCard__cta'}
                                target={(cta.target || '_blank')}
                                href={cta.href}
                >
                    {cta.title}
                </TextLinkButton>
            }
        </div>
    )
};


const DevelopersPage = () => {
    const { t } = useTranslation();

    return (
        <div className={'Page DevelopersPage'}>
            <PageSection>
                <PageBackground src={devsBackground}/>
                <HeroContent color={'purple'}
                             category={t('navbar.developers')}
                             title={t('developers.title')}
                             subtitle={<Markdown>{t('developers.subtitle')}</Markdown>}
                />

                <div className={'DevelopersPage__ctas'}>
                    <Button href={DocsHomeUrl}
                            target={'_blank'}
                            size={'large'}
                            color={'green'}
                            className={'MainCTAButton'}
                    >
                        {t('developers.cta-1')}
                    </Button>
                </div>

                <div className={'DeveloperCards'}
                >
                    <DeveloperCard title={t('developers.cards.smart-contract.title')}
                                   subtitle={t('developers.cards.smart-contract.subtitle')}
                                   cta={{
                                       title: t('developers.cards.smart-contract.cta-title'),
                                       href: DocsSmartContractsUrl
                                   }}
                    />
                    <DeveloperCard title={t('developers.cards.edge-cloud.title')}
                                   subtitle={t('developers.cards.edge-cloud.subtitle')}
                                   cta={{
                                       title: t('developers.cards.edge-cloud.cta-title'),
                                       href: EdgeCloudUrl
                                   }}
                    />
                    {/*<DeveloperCard title={t('developers.cards.tnt20.title')}*/}
                    {/*               subtitle={t('developers.cards.tnt20.subtitle')}*/}
                    {/*               cta={{*/}
                    {/*                   title: t('developers.cards.tnt20.cta-title'),*/}
                    {/*                   href: DocsTNT20TutorialUrl*/}
                    {/*               }}*/}
                    {/*/>*/}
                    <DeveloperCard title={t('developers.cards.github.title')}
                                   subtitle={t('developers.cards.github.subtitle')}
                                   cta={{
                                       title: t('developers.cards.github.cta-title'),
                                       href: GithubUrl
                                   }}
                    />
                    <DeveloperCard title={t('developers.cards.ecosystem.title')}
                                   subtitle={t('developers.cards.ecosystem.subtitle')}
                                   cta={{
                                       title: t('developers.cards.ecosystem.cta-title'),
                                       href: '/ecosystem',
                                       target:'_self'
                                   }}
                    />
                </div>

                <SectionTitle title={t('developers.logos.p')}/>
                <PartnerLogos logos={[
                    pLogosLine, pLogosKlaytn, pLogosChainlink, pLogosReplay, pLogosMetapass, pLogosOpentheta, pLogosPassaways, pLogosUltra, pLogosDecentraland
                ]}/>

                <div className={'DevelopersMainnet4'}
                     id={'edgecloud-overview'}
                >
                    <div className={'DevelopersMainnet4__imagery'}>
                        <img src={edgeCloudImage}
                             style={{padding: 60}}
                        />
                    </div>
                    <div className={'DevelopersMainnet4__info'}>
                        <div className={'DevelopersMainnet4__rows'}>
                            <div className={'DevelopersMainnet4__row'}>
                                <p>{t('developers.products.edgecloud.description')}</p>
                            </div>
                            <div className={'DevelopersMainnet4__row'}>
                                <div>{t('developers.products.edgecloud.sections.ai-services.title')}</div>
                                <p>{t('developers.products.edgecloud.sections.ai-services.description')}</p>
                                <TextLinkButton href={DocsEdgeCloudServicesAI}
                                                target={'_blank'}
                                >
                                    {t('developers.products.edgecloud.sections.ai-services.cta-title')}
                                </TextLinkButton>
                            </div>
                            <div className={'DevelopersMainnet4__row'}>
                                <div>{t('developers.products.edgecloud.sections.video-services.title')}</div>
                                <p>{t('developers.products.edgecloud.sections.video-services.description')}</p>
                                <TextLinkButton href={DocsEdgeCloudServicesVideo}
                                                target={'_blank'}>
                                    {t('developers.products.edgecloud.sections.video-services.cta-title')}
                                </TextLinkButton>
                            </div>
                            {/*<div className={'DevelopersMainnet4__row'}>*/}
                            {/*    <p>EdgeCloud Rendering Services</p>*/}
                            {/*    <TextLinkButton href={DocsEdgeCloudServicesRendering}*/}
                            {/*                    target={'_blank'}>*/}
                            {/*        View Rendering Docs*/}
                            {/*    </TextLinkButton>*/}
                            {/*</div>*/}
                        </div>

                        <Button color={'green'}
                                size={'large'}
                                href={EdgeCloudUrl}
                                target={'_blank'}
                        >
                            {t('developers.products.edgecloud.cta-title')}
                        </Button>
                    </div>
                </div>


                <div className={'DevelopersMainnet4'}
                     id={'metachain-overview'}
                >
                    <div className={'DevelopersMainnet4__imagery'}>
                        <img src={mn4Image}/>
                    </div>
                    <div className={'DevelopersMainnet4__info'}>
                        <div className={'DevelopersMainnet4__rows'}>
                            <div className={'DevelopersMainnet4__row'}>
                                <p>
                                    {t('developers.products.metachain.description')}
                                </p>
                            </div>
                            <div className={'DevelopersMainnet4__row'}>
                                <p>{t('developers.products.metachain.sections.subchain.description')}</p>
                                <TextLinkButton href={WhitepapersMainnet4Url}
                                                target={'_blank'}
                                >
                                    {t('developers.products.metachain.sections.subchain.cta-title')}
                                </TextLinkButton>
                            </div>
                            <div className={'DevelopersMainnet4__row'}>
                                <p>{t('developers.products.metachain.sections.video.description')}</p>
                                <TextLinkButton href={DocsThetaVideoAPIUrl}
                                                target={'_blank'}>
                                    {t('developers.products.metachain.sections.video.cta-title')}
                                </TextLinkButton>
                            </div>
                            <div className={'DevelopersMainnet4__row'}>
                                <p>{t('developers.products.metachain.sections.token.description')}</p>
                                <TextLinkButton href={DocsTNT20TutorialUrl}
                                                target={'_blank'}>
                                    {t('developers.products.metachain.sections.token.cta-title')}
                                </TextLinkButton>
                            </div>
                        </div>

                        <Button color={'green'}
                                size={'large'}
                                href={WhitepapersMainnet4Url}
                                target={'_blank'}
                        >
                            {t('developers.products.metachain.cta-title')}
                        </Button>
                    </div>
                </div>

                <Quotes>
                    <Quote body={t('quotes.replay.body')}
                           logo={quoteLogosReplay}
                    />
                    <Quote body={t('quotes.opentheta.body')}
                           logo={quoteLogosOpenTheta}
                    />
                    <Quote body={t('quotes.passaways.body')}
                           logo={quoteLogosPassaways}
                    />
                </Quotes>
            </PageSection>

        </div>
    )
};

export default DevelopersPage;