import _ from 'lodash';
import {useTranslation} from "react-i18next";
import TextTransition, { presets } from 'react-text-transition';
import { TypeAnimation } from 'react-type-animation';
import PageBackground from "../components/PageBackground";
import {DownloadButton, TextLinkButton} from "../components/Button";
import {
    WhitepapersMainnet4Url,
    ThetaVideoAPIUrl,
    MetachainLaunchDate,
    DownloadsENWindowsUrl,
    DownloadsENMacOSUrl,
    DownloadsENLinuxUrl,
    WhitepapersEdgeCloudUrl,
    EdgeCloudLaunchDate,
    EdgeCloudAIShowcaseUrl,
    EdgeCloudUrl, EliteBoosterLaunchDate, MobileEdgeNodeLaunchDate, EdgeCloudAIModelExplorerUrl, DownloadsENAndroidUrl
} from "../constants";

import background from '../assets/backgrounds/home.jpg';
import metachainVisual from '../assets/images/metachain/visual@2x.png';
import metachainWordmark from '../assets/images/metachain/wordmark@2x.png';
import InfrastructureVideoAPI from '../assets/images/infrastructure/web3-video-api.svg';
import InfrastructureMetachain from '../assets/images/infrastructure/metachain.svg';
import InfrastructureNFTDRM from '../assets/images/infrastructure/nft-drm.svg';
import InfrastructureEdgeNetwork from '../assets/images/infrastructure/global-edge-network.svg';
import InfrastructureValidators from '../assets/images/infrastructure/enterprise-validators.svg';
import InfrastructureArrowLeft from '../assets/images/infrastructure/arrow-left@2x.png';
import InfrastructureArrowRight from '../assets/images/infrastructure/arrow-right@2x.png';
import InfrastructureArrowDown from '../assets/images/infrastructure/arrow-down@2x.png';
import edgeCloudHeroImage from '../assets/images/hero/edgecloud@2x.png';

import quoteLogosCAA from '../assets/logos/quotes/caa@2x.png';
import SectionTitle from "../components/SectionTitle";
import PageSection from "../components/PageSection";
import {useEffect, useState} from "react";
import NewsArticle from "../components/NewsArticle";
import Quotes from "../components/Quotes";
import Quote from "../components/Quote";
import useMediaQuery from "../hooks/useMediaQuery";
import NewsBanner from "../components/NewsBanner";
import quoteLogosGoogle from "../assets/logos/quotes/google@2x.png";
import quoteLogosSony from "../assets/logos/quotes/sony@2x.png";
import quoteLogosAmericanIdol from "../assets/logos/quotes/american-idol@2x.png";
import useInterval from "../hooks/useInterval";
import {buildCountdownDisplay} from "../utils";
import {NodesMap} from "../components/NodesMap";
import pIconsWindows from "../assets/icons/platforms/windows@2x.png";
import pIconsApple from "../assets/icons/platforms/apple@2x.png";
import pIconsLinux from "../assets/icons/platforms/linux@2x.png";
import pIconsAndroid from "../assets/icons/platforms/android@2x.png";
import eIconsEN from "../assets/icons/earn/tfuel-no-shadow@2x.png";
import i18n from "i18next";

const Metachain = () => {
    return (
        <div className={'Metachain'}>
            <img src={metachainVisual}
                 className={'Metachain__visual'}
            />
            <img src={metachainWordmark}
                 className={'Metachain__wordmark'}/>
        </div>
    )
};

const Countdown = ({date}) => {
    const [countdown, setCountdown] = useState(buildCountdownDisplay(date));
    const updateCountdown = () => {
        setCountdown(buildCountdownDisplay(date));
    }
    useInterval(updateCountdown, 1000);

    return (
        <div className={'Countdown'}>
            {countdown}
        </div>
    )
};


const InfrastructureItem = ({id, icon, title, subtitle, href, target}) => {
    const {t} = useTranslation();

    return (
        <div className={'InfrastructureItem'}
             id={id}
        >
            <img src={icon}
                 id={`${id}-icon`}
                 className={'InfrastructureItem__icon'}
            />
            <div className={'InfrastructureItem__title'}>
                {title}
            </div>
            <div className={'InfrastructureItem__subtitle'}>
                {subtitle}
            </div>
            <TextLinkButton target={target}
                            href={href}>
                {t('common.learn-more')}
            </TextLinkButton>
        </div>
    )
};

const clearDynamicLines = () => {
    var lines = document.getElementsByClassName('DynamicLine');

    while(lines[0]) {
        lines[0].parentNode.removeChild(lines[0]);
    }
}

const drawMobileLines = () => {
    clearDynamicLines();

    const elements = document.getElementsByClassName('InfrastructureItem__icon');
    const elById = _.keyBy(elements, 'id');
    const containerEl = document.getElementById('infrastructure-items');
    const containerRect = containerEl.getBoundingClientRect();
    const videoAPIRect = elById['infrastructure.video-api-icon'].getBoundingClientRect();
    const metachainRect = elById['infrastructure.metachain-icon'].getBoundingClientRect();
    const nftDRMRect = elById['infrastructure.nft-drm-icon'].getBoundingClientRect();
    const edgeNetworkRect = elById['infrastructure.edge-network-icon'].getBoundingClientRect();
    const validatorsRect = elById['infrastructure.validators-icon'].getBoundingClientRect();


    // Web3 -> Metachain
    const iconSize = videoAPIRect.width;
    let line, arrow, square, top, left, width, height;
    square = document.createElement('div');
    square.className = 'DynamicLine';
    left = videoAPIRect.left + iconSize - containerRect.left + 12;
    top = (iconSize / 2);
    width = metachainRect.left - edgeNetworkRect.left - (iconSize / 2) - 12 - 6;
    height = metachainRect.top - top - containerRect.top - 12;
    square.style.cssText = `border: 1px dashed #7A7DA3; position:absolute; top: ${top}px; left: ${left}px; width: ${width}px; height: ${height}px; border-bottom: 0; border-left: 0; border-top-right-radius: 30px;`;
    containerEl.appendChild(square);
    arrow = document.createElement('img');
    arrow.src = InfrastructureArrowDown;
    arrow.className = 'DynamicLine';
    arrow.style.cssText = `position:absolute; bottom: ${-2}px; right: ${-3}px; width: ${5}px; height: ${3}px;`;
    square.appendChild(arrow);

    // nftDRM -> Metachain
    square = document.createElement('div');
    square.className = 'DynamicLine';
    left = metachainRect.left + iconSize / 2 - containerRect.left + 6;
    top = (iconSize / 2);
    width = metachainRect.left - edgeNetworkRect.left - (iconSize / 2) - 12;
    height = metachainRect.top - top - containerRect.top - 12;
    square.style.cssText = `border: 1px dashed #7A7DA3; position:absolute; top: ${top}px; left: ${left}px; width: ${width}px; height: ${height}px; border-bottom: 0; border-right: 0; border-top-left-radius: 30px;`;
    containerEl.appendChild(square);
    arrow = document.createElement('img');
    arrow.src = InfrastructureArrowDown;
    arrow.className = 'DynamicLine';
    arrow.style.cssText = `position:absolute; bottom: ${-2}px; left: ${-3}px; width: ${5}px; height: ${3}px;`;
    square.appendChild(arrow);

    // Metachain -> edgeNetwork
    square = document.createElement('div');
    square.className = 'DynamicLine';
    left = edgeNetworkRect.left + (iconSize / 2) - containerRect.left;
    top = metachainRect.top + (iconSize / 2) - containerRect.top;
    width = metachainRect.left - edgeNetworkRect.left - (iconSize / 2) - 12;
    height = edgeNetworkRect.top - top - containerRect.top - 12;
    square.style.cssText = `border: 1px dashed #7A7DA3; position:absolute; top: ${top}px; left: ${left}px; width: ${width}px; height: ${height}px; border-bottom: 0; border-right: 0; border-top-left-radius: 30px`;
    containerEl.appendChild(square);
    arrow = document.createElement('img');
    arrow.src = InfrastructureArrowDown;
    arrow.className = 'DynamicLine';
    arrow.style.cssText = `position:absolute; bottom: ${-2}px; left: ${-3}px; width: ${5}px; height: ${3}px;`;
    square.appendChild(arrow);

    // Metachain -> validators
    square = document.createElement('div');
    square.className = 'DynamicLine';
    left = metachainRect.left + iconSize - containerRect.left + 12;
    top = metachainRect.top + (iconSize / 2) - containerRect.top;
    width = metachainRect.left - edgeNetworkRect.left - (iconSize / 2) - 12;
    height = edgeNetworkRect.top - top - containerRect.top - 12;
    square.style.cssText = `border: 1px dashed #7A7DA3; position:absolute; top: ${top}px; left: ${left}px; width: ${width}px; height: ${height}px; border-bottom: 0; border-left: 0; border-top-right-radius: 30px`;
    containerEl.appendChild(square);
    arrow = document.createElement('img');
    arrow.src = InfrastructureArrowDown;
    arrow.className = 'DynamicLine';
    arrow.style.cssText = `position:absolute; bottom: ${-2}px; right: ${-3}px; width: ${5}px; height: ${3}px;`;
    square.appendChild(arrow);
};

const drawDesktopLines = () => {
    clearDynamicLines();

    const elements = document.getElementsByClassName('InfrastructureItem__icon');
    const elById = _.keyBy(elements, 'id');
    const containerEl = document.getElementById('infrastructure-items');
    const containerRect = containerEl.getBoundingClientRect();
    const videoAPIRect = elById['infrastructure.video-api-icon'].getBoundingClientRect();
    const metachainRect = elById['infrastructure.metachain-icon'].getBoundingClientRect();
    const nftDRMRect = elById['infrastructure.nft-drm-icon'].getBoundingClientRect();
    const edgeNetworkRect = elById['infrastructure.edge-network-icon'].getBoundingClientRect();
    const validatorsRect = elById['infrastructure.validators-icon'].getBoundingClientRect();

    // VideoAPI -> Metachain
    const iconSize = videoAPIRect.width;
    let line, arrow, square, top, left, width, height;
    height = 1;
    line = document.createElement('div');
    left = videoAPIRect.left - containerRect.left + iconSize + 12;
    top = iconSize / 2;
    width = metachainRect.left - videoAPIRect.left - iconSize - 24;
    line.className = 'DynamicLine';
    line.style.cssText = `border-top: 1px dashed #7A7DA3; position:absolute; top: ${top}px; left: ${left}px; width: ${width}px; height: ${height}px;`;
    containerEl.appendChild(line);
    arrow = document.createElement('img');
    arrow.src = InfrastructureArrowRight;
    arrow.className = 'DynamicLine';
    arrow.style.cssText = `position:absolute; top: ${-6.5}px; right: ${-2}px; width: ${7.5}px; height: ${12.5}px;`;
    line.appendChild(arrow);


    // Metachain -> nftDRM
    line = document.createElement('div');
    left = metachainRect.left - containerRect.left + iconSize + 12;
    top = iconSize / 2;
    width = nftDRMRect.left - metachainRect.left - iconSize - 24;
    line.className = 'DynamicLine';
    line.style.cssText = `border-top: 1px dashed #7A7DA3; position:absolute; top: ${top}px; left: ${left}px; width: ${width}px; height: ${height}px;`;
    containerEl.appendChild(line);
    arrow = document.createElement('img');
    arrow.src = InfrastructureArrowLeft;
    arrow.className = 'DynamicLine';
    arrow.style.cssText = `position:absolute; top: ${-6.5}px; left: ${-2}px; width: ${7.5}px; height: ${12.5}px;`;
    line.appendChild(arrow);

    // Metachain -> edgeNetwork
    square = document.createElement('div');
    square.className = 'DynamicLine';
    left = edgeNetworkRect.left + (iconSize / 2) - containerRect.left;
    top = (iconSize / 2) + 12;
    width = metachainRect.left - edgeNetworkRect.left - (iconSize / 2) - 12;
    height = edgeNetworkRect.top - top - containerRect.top - 12;
    square.style.cssText = `border: 1px dashed #7A7DA3; position:absolute; top: ${top}px; left: ${left}px; width: ${width}px; height: ${height}px; border-bottom: 0; border-right: 0; border-top-left-radius: 30px`;
    containerEl.appendChild(square);
    arrow = document.createElement('img');
    arrow.src = InfrastructureArrowDown;
    arrow.className = 'DynamicLine';
    arrow.style.cssText = `position:absolute; bottom: ${-2}px; left: ${-6.5}px; width: ${12.5}px; height: ${7.5}px;`;
    square.appendChild(arrow);

    // Metachain -> validators
    square = document.createElement('div');
    square.className = 'DynamicLine';
    left = metachainRect.left + iconSize - containerRect.left + 12;
    top = (iconSize / 2) + 12;
    width = metachainRect.left - edgeNetworkRect.left - (iconSize / 2) - 12;
    height = edgeNetworkRect.top - top - containerRect.top - 12;
    square.style.cssText = `border: 1px dashed #7A7DA3; position:absolute; top: ${top}px; left: ${left}px; width: ${width}px; height: ${height}px; border-bottom: 0; border-left: 0; border-top-right-radius: 30px`;
    containerEl.appendChild(square);
    arrow = document.createElement('img');
    arrow.src = InfrastructureArrowDown;
    arrow.className = 'DynamicLine';
    arrow.style.cssText = `position:absolute; bottom: ${-2}px; right: ${-6.5}px; width: ${12.5}px; height: ${7.5}px;`;
    square.appendChild(arrow);
};

const InfrastructureItems = () => {
    const {t} = useTranslation();
    const isMobile = useMediaQuery(768);

    useEffect(() => {
        if(isMobile){
            drawMobileLines();
        }
        else{
            drawDesktopLines();
        }
    });

    const videoAPI = (
        <InfrastructureItem
            id={'infrastructure.video-api'}
            title={t('infrastructure.video-api.title')}
            subtitle={t('infrastructure.video-api.subtitle')}
            icon={InfrastructureVideoAPI}
            href={'/enterprises#video-technology-partners'}
        />
    );
    const metachain = (
        <InfrastructureItem
            id={'infrastructure.metachain'}
            title={t('infrastructure.metachain.title')}
            subtitle={t('infrastructure.metachain.subtitle')}
            icon={InfrastructureMetachain}
            href={'/developers#metachain-overview'}
        />
    );
    const nftDRM = (
        <InfrastructureItem
            id={'infrastructure.nft-drm'}
            title={t('infrastructure.nft-drm.title')}
            subtitle={t('infrastructure.nft-drm.subtitle')}
            icon={InfrastructureNFTDRM}
            href={"/enterprises#media-partners"}
        />
    );
    const edge = (
        <InfrastructureItem
            id={'infrastructure.edge-network'}
            title={t('infrastructure.edge-network.title')}
            subtitle={t('infrastructure.edge-network.subtitle')}
            icon={InfrastructureEdgeNetwork}
            href={'/community#earn-and-stake'}
        />
    );
    const validators = (
        <InfrastructureItem
            id={'infrastructure.validators'}
            title={t('infrastructure.validators.title')}
            subtitle={t('infrastructure.validators.subtitle')}
            icon={InfrastructureValidators}
            href={"/enterprises#validator-partners"}
        />
    );

    if(isMobile){
        return (
            <div className={'InfrastructureItems MobileLayout'}
                 id={'infrastructure-items'}
            >
                <div className={'InfrastructureItemsRow'}>
                    {videoAPI}
                    {nftDRM}
                </div>
                <div className={'InfrastructureItemsRow'}>
                    <div/>
                    {metachain}
                    <div/>
                </div>
                <div className={'InfrastructureItemsRow'}>
                    {edge}
                    {validators}
                </div>
            </div>
        );
    }
    else{
        return (
            <div className={'InfrastructureItems'}
                 id={'infrastructure-items'}
            >
                <div className={'InfrastructureItemsRow'}>
                    {videoAPI}
                    {metachain}
                    {nftDRM}
                </div>
                <div className={'InfrastructureItemsRow'}>
                    <div/>
                    {edge}
                    {validators}
                    <div/>
                </div>
            </div>
        );
    }
};

const EdgeNodeDownloadCTA = () => {
    const {t} = useTranslation();

    return (
        <div className={'EdgeNodeDownloadCTA'}>
            <div className={'EdgeNodeDownloadCTA__grey-title'}>
                {t('edge-node-cta.grey-title')}
            </div>
            <div className={'EdgeNodeDownloadCTA__title'}>
                <span>
                    {t('edge-node-cta.title')}
                </span>
                <img src={eIconsEN}/>
                <span>
                    {t('common.tfuel')}
                </span>
            </div>
            <div className={'EdgeNodeDownloadCTA__subtitle'}>
                {t('edge-node-cta.subtitle')}
            </div>
            <div className={'EdgeNodeDownloadCTA__buttons'}>
                <DownloadButton title={'Windows'}
                                icon={pIconsWindows}
                                href={DownloadsENWindowsUrl}
                />
                <DownloadButton title={'macOS'}
                                icon={pIconsApple}
                                href={DownloadsENMacOSUrl}
                />
                <DownloadButton title={'Linux'}
                                icon={pIconsLinux}
                                href={DownloadsENLinuxUrl}
                />
                <DownloadButton title={'Android'}
                                icon={pIconsAndroid}
                                href={DownloadsENAndroidUrl}
                />
            </div>
        </div>
    )
}

const HomePage = () => {
    const {t} = useTranslation();
    const [showAllNewsArticles, setShowAllNewsArticles] = useState(false);
    const [newsArticles, setNewsArticles] = useState([]);
    const featuresNewsArticle = _.first(newsArticles);
    const [taglineIndex, setTagLineIndex] = useState(0);
    const TAGLINES = [
        t('home.main-title-tagline-3'),
        t('home.main-title-tagline-1'),
        t('home.main-title-tagline-2'),
        t('home.main-title-tagline-4'),
        t('home.main-title-tagline-5'),
    ]
    useEffect(() => {
        const intervalId = setInterval(
            () => setTagLineIndex((index) => index + 1),
            5000,
        );
        return () => clearTimeout(intervalId);
    }, []);

    const fetchNewsArticles = async () => {
        // Get the current language
        const language = i18n.language;
        console.log('language', language)
        let url = 'https://sheet2api.com/v1/RSBMaYwSHCof/theta-network-news';
        if(language === 'kr'){
            url = 'https://sheet2api.com/v1/RSBMaYwSHCof/theta-network-news-korean';
        }
        const response = await fetch(url);
        setNewsArticles(await response.json());
    }

    useEffect(() => {
        document.body.style.backgroundColor = '#09090D';

        fetchNewsArticles();

        return () => {
            document.body.style.backgroundColor = null;
        };
    }, []);

    return (
        <div className={'Page HomePage'}>
            <PageBackground src={background}/>
            {
                featuresNewsArticle &&
                <NewsBanner {...featuresNewsArticle}/>
            }

            <PageSection className={'HeroSection'}>
                <div className={'HomePage__main-title-and-subtitle'}>
                    <div className={'HomePage__main-title'}>
                        {t('home.main-title-tagline')}
                        {/*<div>*/}
                        {/*    {t('home.main-title-reinventing')}*/}
                        {/*</div>*/}
                        {/*<TypeAnimation*/}
                        {/*    sequence={[*/}
                        {/*        t('home.main-title-tagline-1'),*/}
                        {/*        5000,*/}
                        {/*        t('home.main-title-tagline-2'),*/}
                        {/*        5000,*/}
                        {/*        t('home.main-title-tagline-3'),*/}
                        {/*        5000,*/}
                        {/*        t('home.main-title-tagline-4'),*/}
                        {/*        5000,*/}
                        {/*        t('home.main-title-tagline-5'),*/}
                        {/*        5000*/}
                        {/*    ]}*/}
                        {/*    wrapper="div"*/}
                        {/*    speed={200}*/}
                        {/*    className={"HomePage__main-title-tagline"}*/}
                        {/*    // style={{minHeight: 80}}*/}
                        {/*    cursor={false}*/}
                        {/*    splitter={(str) => str.split(/(?= )/)}*/}
                        {/*    preRenderFirstString={true}*/}
                        {/*    // style={{ fontSize: '2em', display: 'inline-block' }}*/}
                        {/*    repeat={Infinity}*/}
                        {/*    omitDeletionAnimation={true}*/}
                        {/*/>*/}
                    </div>
                    {/*<div className={'HomePage__main-subtitle'}>*/}
                    {/*    <div>*/}
                    {/*        {t('home.main-subtitle')}*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
                <a className={'UpcomingRelease'}
                   href={EdgeCloudUrl}
                   target={'_blank'}
                >
                    {/*<Metachain/>*/}
                    <img src={edgeCloudHeroImage}
                         className={'UpcomingRelease__edgecloud-image'}
                    />
                    {/*<div className={'UpcomingRelease__launch-date'}*/}
                    {/*     style={{marginTop: 15}}*/}
                    {/*>*/}
                    {/*    {t('home.hero.edgecloud.now-available')}*/}
                    {/*</div>*/}
                    <TextLinkButton href={EdgeCloudAIModelExplorerUrl}
                                    target={'_blank'}
                    style={{marginBottom: 12}}>
                        {t('home.edgecloud-action-button')}
                    </TextLinkButton>
                    {/*<div className={'UpcomingRelease__launch-date'}*/}
                    {/*     style={{marginTop: 24,*/}
                    {/*         marginBottom: -18,*/}
                    {/*         color: '#9EA2D4'*/}
                    {/*}}*/}
                    {/*>*/}
                    {/*    <div>{t('home.hero.mobile-edge-node.title')}</div>*/}
                    {/*</div>*/}
                    {/*<Countdown date={MobileEdgeNodeLaunchDate}/>*/}
                    {/*<div className={'UpcomingRelease__launch-date'}*/}
                    {/*     style={{marginTop: 8}}*/}
                    {/*>*/}
                    {/*    {t('home.edgenode-mobile-launch-date')}*/}
                    {/*</div>*/}
                    <div className={'UpcomingRelease__title'}>
                        {t('home.hero.mobile-edge-node.subtitle')}
                    </div>
                    <TextLinkButton href={'https://play.google.com/store/apps/details?id=org.theta.edge_node_mobile'}
                                    target={'_blank'}>
                        {t('common.download-now')}
                    </TextLinkButton>
                    {/*<TextLinkButton href={'/developers#metachain-overview'}>*/}
                    {/*    {t('common.learn-more')}*/}
                    {/*</TextLinkButton>*/}
                </a>
            </PageSection>

            <EdgeNodeDownloadCTA/>

            <PageSection className={'NodesMapSection'}>
                <SectionTitle title={t('home.live-nodes')} weight={600} center/>
                <NodesMap/>
            </PageSection>

            {
                !_.isEmpty(newsArticles) &&
                <PageSection className={'NewsSection'}>
                    <SectionTitle title={t('home.recent-news')} weight={600} center/>
                    <TextLinkButton className={'NewsSection__view-all-news'}
                                    onClick={() => {
                                        setShowAllNewsArticles(!showAllNewsArticles)
                                    }}
                    >
                        {
                            !showAllNewsArticles &&
                            t('home.view-more-news')
                        }
                        {
                            showAllNewsArticles &&
                            t('home.view-less-news')
                        }
                    </TextLinkButton>

                    <div className={'NewsArticles'}>
                        {
                            _.map(_.take(newsArticles, (showAllNewsArticles ? 100 : 12)), (newsArticle) => {
                                return <NewsArticle {...newsArticle}/>
                            })
                        }
                    </div>
                    <TextLinkButton className={'NewsSection__view-all-news'}
                                    onClick={() => {
                                        setShowAllNewsArticles(!showAllNewsArticles)
                                    }}
                    >
                        {
                            !showAllNewsArticles &&
                            t('home.view-more-news')
                        }
                        {
                            showAllNewsArticles &&
                            t('home.view-less-news')
                        }
                    </TextLinkButton>
                </PageSection>
            }

            {/*<PageSection className={'VideoInfrastructureSection'}>*/}
            {/*    <SectionTitle title={t('home.theta-video-infrastructure')} weight={600} center/>*/}
            {/*    <InfrastructureItems/>*/}
            {/*</PageSection>*/}

            <PageSection className={'NewsSection'}>
                <Quotes center>
                    <Quote body={t('quotes.caa.body')}
                           name={t('quotes.caa.name')}
                           logo={quoteLogosCAA}
                           center
                    />
                    <Quote body={t('quotes.google.body')}
                           logo={quoteLogosGoogle}
                           center
                    />
                    <Quote body={t('quotes.sony.body')}
                           logo={quoteLogosSony}
                           center
                    />
                    <Quote body={t('quotes.american-idol.body')}
                           logo={quoteLogosAmericanIdol}
                           center
                    />
                </Quotes>
            </PageSection>


        </div>
    )
};

export default HomePage;