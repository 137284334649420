import React, { useState, useEffect, useRef } from 'react';
import PageSection from "../components/PageSection";

const PrivacyPolicyPage = () => {
    const [scrolling, setScrolling] = useState(true);
    const iframeRef = useRef(null);

    useEffect(() => {
        console.log("Page componentDidUpdate");
    });

    return (
        <div className="Page terms-page">
            <PageSection>
                <iframe
                    id="the-document"
                    src="/html/privacy-policy/index.html"
                    ref={iframeRef}
                />
            </PageSection>
        </div>
);
};

export default PrivacyPolicyPage;