const translations = {
    en: {
        translation: {
            "welcome": "Welcome to React and react-i18next",
            "navbar": {
                "official-links": "Official Links",
                "enterprises": "Enterprises",
                "video-technology-partners": 'AI Technology Partners',
                "content-and-media-partners": 'Content & Media Partners',
                "validator-partners": 'Validator Partners',
                "developers": "Devs",
                'resources': 'Resources',
                'metachain-overview': "Metachain Overview",
                "community": "Community",
                'theta-edge-network': 'Theta Edge Network',
                'earn-stake-trade': 'Earn, Stake & Trade',
                'product-roadmap': 'Product Roadmap',
                "explorer": "Explorer",
                "wallet": "Wallet",
                "about": "About",
                "docs": "Docs",
                "support": "Support",
                'ecosystem': 'Ecosystem',
                "security": "Security",
            },
            'edge-node-cta': {
                'grey-title': 'Node Operators:',
                'title': 'Share your GPU for AI & Video workloads to earn',
                'subtitle': 'Download Theta Edge Node'
            },
            "home": {
                "main-title-tagline": "The Decentralized Cloud for AI, Media & Entertainment",
                "main-title-reinventing": "Reinventing",
                "main-title-tagline-1": "AI at the Edge",
                "main-title-tagline-2": "GPU as currency of the future",
                "main-title-tagline-3": "DePIN: resource sharing economy",
                "main-title-tagline-4": "Rendering with 3D GenAI",
                "main-title-tagline-5": "Video Web3 Infrastructure",
                "main-subtitle": "The Decentralized Cloud for\nAI, Media & Entertainment",
                'edgecloud-title': 'The major upgrade to\nTheta Elite Booster nodes is here',
                'edgecloud-launch-date': 'June 26th, 2024',
                'edgecloud-action-button': 'Visit Dashboard for AI Developers ->',
                "mainnet-4-launching": 'MAINNET 4.0 LAUNCHED 2023',
                'edgenode-mobile-launch-date': 'Sept 25, 2024',
                'theta-video-infrastructure': 'Theta Video Infrastructure',
                'recent-news': 'Recent News',
                'view-all-news': 'View all news →',
                'view-more-news': 'View more ↓',
                'view-less-news': 'View less ↑',
                'live-nodes': 'Live Nodes',
                'hero': {
                    'edgecloud': {
                        'now-available': 'Is now Available'
                    },
                    'mobile-edge-node': {
                        'title': 'Mobile Edge Node Launch',
                        'subtitle': 'Mobile Edge Node for Android Is Now Available',
                    }
                }
            },
            "infrastructure": {
                "video-api": {
                    "title": "Web3 Video API",
                    "subtitle": "Lower your cost to encode, store and deliver video"
                },
                "metachain": {
                    "title": "Metachain",
                    "subtitle": "Customizable, highly scalable blockchain for your Web3 business"
                },
                "edge-network": {
                    "title": "Global Edge Network",
                    "subtitle": "Share bandwidth, storage and computing to earn rewards"
                },
                "nft-drm": {
                    "title": "NFTs with DRM",
                    "subtitle": "Monetize your content and IP, secure with digital rights"
                },
                "validators": {
                    "title": "Enterprise Validators",
                    "subtitle": "Join global leaders to secure and govern Theta network"
                },
            },
            "enterprises": {
                'vtp-section': {
                    'title': 'AI Developers & Teams',
                    'subtitle': 'Join leading AI enterprises and universities using EdgeCloud for AI model training and inference',
                    'point-1': 'Theta EdgeCloud delivers the best price-to-performance and the fastest sign-up to direct access to GPU nodes/clusters via SSH.',
                    'point-2': 'Leverage the power of 30,000+ distributed Nvidia 3090/4090s nodes for inference and training.',
                    'point-3': 'On-demand enterprise grade Nvidia A100/H100 and more at 50-70% cost savings.',
                    'cta-1': 'ThetaEdgeCloud.com',
                    'cta-2': 'Theta EdgeCloud AI docs →',
                    'logos': {
                        'vtp': 'AI Technology Partners'
                    }
                },
                'cmp-section': {
                    'title': 'Content & Media Partners',
                    'subtitle': 'Expand beyond ad-supported, subscription and pay-per-view revenue into Web3 NFT ownership and membership models.',
                    'point-1': 'Launch into the world of Web3 and Metaverse with Theta’s NFT-based Digital Rights Management (DRM).',
                    'point-2': 'Leverage ThetaDrop end-to-end full service NFT platform to increase fan engagement and customer loyalty.',
                    'point-3': 'Built on Theta’s patent #17/218,245, implement DRM via NFTs in a decentralized network.',
                    'cta-1': 'ThetaDrop.com',
                    'cta-2': 'NFT-based DRM documentation →',
                    'logos': {
                        'cmp': 'Content and Media Partners'
                    }
                },
                'vp-section': {
                    'title': 'Theta Validator Partners',
                    'subtitle': 'Join global partners to help secure and govern the leading media blockchain in the world.',
                    'point-1': 'Join Global 100 corporations to help secure, govern and decentralize Theta network.',
                    'point-2': 'Be on the cutting edge of Web3 and blockchain technologies, participate in the leading public blockchain for media & entertainment.',
                    'point-3': 'Earn TFUEL rewards by staking THETA.',
                    'cta-1': 'Theta Validator Documentation',
                    'logos': {
                        'mt': 'Media and Technology',
                        'bc': 'Blockchain',
                        'ii': 'Institutional Investors',
                    }
                }
            },
            "developers": {
                "title": "What is Theta Network?",
                "subtitle": "Theta is the leading decentralized cloud for AI, media and entertainment. [Theta EdgeCloud](https://thetaedgecloud.com/) is the first hybrid cloud-edge computing AI platform, powered by the Theta Edge Network with over 30,000 distributed global edge nodes and 80 PetaFLOPS of always available distributed GPU compute power. Theta is among the top 10 DePIN blockchains by market capitalization listed on both [Coinmarketcap](https://coinmarketcap.com/view/depin/) and [Coingecko](https://www.coingecko.com/en/categories/depin), and among the top [AI tokens on Binance.com](https://www.binance.com/en/markets/coinInfo-AI).\n\n[EdgeCloud Video](https://www.thetaedgecloud.com/dashboard/video/overview) is a turn-key Web3 video API for developers offering significantly lower video transcoding and content delivery costs. Theta’s Web3 infrastructure including [ThetaDrop](https://www.thetadrop.com/) NFT marketplace and [Theta Web3 Theatre](https://www.thetaedgecloud.com/dashboard/video/web3theatre) enable entertainment companies to drive new revenues and community engagement.",
                "cta-1": "Learn more",
                "cards": {
                    "smart-contract": {
                        'title': 'Smart contract and DApp development',
                        'subtitle': 'Learn how to deploy EVM-compatible smart contracts and Dapps on Theta using Remix, Hardhat, and Truffle Suite',
                        'cta-title': 'View Documentation →'
                    },
                    "edge-cloud": {
                        'title': 'Theta EdgeCloud',
                        'subtitle': 'The First Hybrid Cloud-Edge Computing Platform for AI, Video, Rendering, Gaming and more.',
                        'cta-title': 'View Platform →'
                    },
                    "tnt20": {
                        'title': 'Theta Network TNT20 Token Tutorial',
                        'subtitle': 'Launch your own TNT-20 token in minutes',
                        'cta-title': 'View Tutorial →'
                    },
                    "github": {
                        'title': 'Theta Labs Github',
                        'subtitle': 'Collaborate on the open-source Theta blockchain with the Theta Labs Git repositories.',
                        'cta-title': 'View GitHub →'
                    },
                    "ecosystem": {
                        'title': 'Theta Ecosystem',
                        'subtitle': 'Learn about the Web3 businesses, video platforms, NFT projects, and more that are building on Theta blockchain.',
                        'cta-title': 'View Projects →'

                    }
                },
                'products': {
                    'edgecloud': {
                        'description': 'The Theta EdgeCloud empowers AI teams globally with unmatched GPU price-to-performance. It offers developers, researchers, and enterprises of all sizes unlimited, cost-effective access to GPU processing for AI, video, rendering, and containerized tasks. Leveraging the Theta Edge Network, it combines the best of Cloud computing with a decentralized system.',
                        'sections': {
                            'ai-services': {
                                'title': 'EdgeCloud AI Services',
                                'description': 'Run popular Generative AI models and integrate them into your application with just a few clicks or lines of code. Easily host custom containerized workloads and prototype using tools like Jupyter Notebook, all with unmatched GPU price-to-performance. Easily configure and setup SSH directly into GPU node and clusters to train your AI models.',
                                'cta-title': 'View AI Docs →'
                            },
                            'video-services': {
                                'title': 'EdgeCloud Video Services',
                                'description': 'Integrate decentralized video into any application with just a few clicks or lines of code—no video stack development required. It\'s simple, cost-effective, and powered by Theta.',
                                'cta-title': 'View Video Docs →'
                            },
                        },
                        'cta-title': 'Explore Theta EdgeCloud →'
                    },
                    'metachain': {
                        'description': 'The Theta Metachain is an interconnected network of blockchains, a “chain of chains”. It will allow permissionless horizontal scaling of the Theta blockchain network in order to achieve potentially unlimited transactional throughput and subsecond block finalization time. This enables a customizable, highly scalable blockchain tailored for emerging Web3 businesses in the media and entertainment space.',
                        'sections': {
                            'subchain': {
                                'description': 'Subchain - horizontal scale, near instant finality',
                                'cta-title': 'View Subchain Docs →'
                            },
                            'video': {
                                'description': 'Web3 video infrastructure to build on.',
                                'cta-title': 'View Video Docs →'
                            },
                            'token': {
                                'description': 'Own governance tokens, user rewards and utility - TNT20',
                                'cta-title': 'View TNT20 Guide →'
                            }
                        },
                        'cta-title': 'Maintnet 4.0 Whitepaper →'
                    }
                },
                "logos": {
                    "p": "Partners"
                }
            },
            "ecosystem": {
                "title": "Built on Theta",
                "subtitle": "Learn about the Web3 businesses, video platforms, NFT projects, and more that are building on Theta blockchain.",
            },
            "community": {
                "title": "Join the Theta community",
                'cta-1': 'Learn more',
                "lns-section": "Live Network Stats",
                "en-section": "Theta Edge Network",
                "eas-section": "Join the Network - Earn & Stake",
                "t-section": "Trade",
                "pr-section": "Product Roadmap"
            },
            "join-the-network": {
                "title": "Join the Network",
            },
            "earning": {
                "edge-node": {
                    'kind': 'Earn',
                    'title': 'Earn TFUEL simply by sharing your PC, Mac and Linux box resources',
                    'subtitle': '(storage, compute and bandwidth)',
                    'cta': 'Theta Edge Node'
                },
                "guardian-node": {
                    'kind': 'Stake',
                    'title': 'Become a Theta Guardian by staking THETA to earn TFUEL',
                    'subtitle': 'Help secure and participate in the Theta Network.',
                    'cta': 'Theta Guardian Node'
                },
                "elite-edge-node": {
                    'kind': 'Stake',
                    'title': 'Power up to an Elite Edge node by staking TFUEL to earn more TFUEL',
                    'subtitle': 'Be part of an elite group of 8,000 edge operators, top 6%',
                    'cta': 'Theta Edge Node'
                },
                "tdrop": {
                    'kind': 'Stake',
                    'title': 'Earn Theta’s NFT liquidity mining token TDROP by staking TDROP',
                    'subtitle': 'Two simple steps from within Theta wallet.',
                    'cta': 'Theta Wallet'
                }
            },
            "roadmap": {
                "col1": {
                    'title': '2024 H1',
                    'items': [
                        'EdgeCloud, next gen edge network, release 1',
                        [
                            'Cloud hosted nodes managed by Theta',
                            'Elite+ Booster features for EEN',
                            'Operations dashboard, virtual management',
                            'Host popular gen-AI, vision and language models'
                        ],
                        'Theta Hackathon - Metachain/EdgeCloud/DePIN focus',
                        'New adoption metrics and subchain stats',
                        'Support new ecosystem projects, subchains, TNT20 staking',
                        'Theta Video API enhancements',
                        [
                            'Self-serve studio-level NFT DRM support for livestreams and VoD',
                            'Advanced metrics dashboard'
                        ],
                    ]
                },
                "col2": {
                    'title': '2024 H2',
                    'items': [
                        'EdgeCloud, next gen edge network, release 2',
                        [
                            'Enable partners to setup and run hosted nodes in their own cloud, data centers',
                            'Support for advanced job prioritization and opt-out',
                            'Summary reports / analytics of jobs completed',
                            'Support distributed temporal and persistent storage for compute jobs'
                        ],
                        'Fully decentralized Theta Video API for DRM, transcoding and delivery',
                        'Next gen Theta Web3 Theater integrated into ThetaDrop and partner platforms',
                        'Extend TDROP use cases to partner marketplaces',
                    ]
                },
                "col3": {
                    'title': '2025+',
                    'items': [
                        'EdgeCloud, next gen edge network, release 3',
                        [
                            'Deploy first hybrid cloud computing platform built on a fully distributed edge architecture',
                            'Jobs and payload marketplace',
                            'Optimization of jobs supply & demand',
                            'Developer API interface',
                        ]
                    ]
                }
            },
            "wallet": {
                'title': 'Store your THETA, TFUEL and TDROP tokens securely and easily',
                'subtitle': 'Send, receive and earn with a variety of wallets in the Theta ecosystem.',
                'platforms-section': {
                    'title': 'Supported Wallet Platforms'
                }
            },
            "official-links": {
                "title": "Official Links",
                "subtitle": "At THETA, your security is our top priority. We've noticed an increase in reports of scams and phishing attempts, and we want to share our official websites.\n\nBeware of unsolicited messages from senders who pretend to be someone you trust. The THETA team will never call you, send an unsolicited direct message via text or social media, ask you to send your funds to an external wallet address or ask you for your account password or security codes.",
            },
            "docs": {
                'title': 'Build on Theta',
                'subtitle': 'Learn how to launch smart contracts, run nodes, and develop Dapps on Theta blockchain',
                'papers-section': {
                    'title': 'Library of Papers'
                },
                'main-docs': {
                    'what-is-theta': {
                        'title': 'What is Theta?',
                        'subtitle': 'Next generation media and entertainment focused blockchain',
                        'cta': {
                            'title': 'View Documentation →'
                        }
                    },
                    'mn4': {
                        'title': 'Mainnet 4.0 Whitepaper',
                        'subtitle': 'Introducing Theta Metachain to power Web3 Businesses',
                        'cta': {
                            'title': 'View Whitepaper →'
                        }
                    },
                    'edge-cloud': {
                        'title': 'EdgeCloud Whitepaper',
                        'subtitle': 'Ushering in a new era of AI computing and more.',
                        'cta': {
                            'title': 'View Whitepaper →',
                        },
                        'cta2': {
                            'title': 'View Docs →',
                        }
                    }
                }
            },
            "about": {
                'title': 'Guided by experience in AI, media and entertainment',
                'subtitle': 'The Theta team is made up of experienced technologists and executives from Salesforce, Netflix, Amazon, Samsung, Vimeo, and Google. Theta’s unique combination of AI + blockchain expertise are the key to empowering AI and video teams with unrivaled GPU price-to-performance.\n\nTheta is led by CEO Mitch Liu, co-founder of Gameview Studios (acquired by DeNA) and Tapjoy (acquired by ironSource), and CTO Jieyi Long, co-founder of MadSkill Game Studios. Collectively they hold over a [dozen patents](https://patents.google.com/?inventor=jieyi+long&oq=jieyi+long&sort=new) in AI, blockchain, video, digital rights management and more.',
                'leadership-team-section': {
                    'title': 'Leadership Team'
                },
                'media-advisors-section': {
                    'title': 'Media Advisors'
                },
                'blockchain-advisors-section': {
                    'title': 'AI & Blockchain Advisors'
                },
                'investors-section': {
                    'title': 'Investors'
                }
            },
            "papers": {
                "multi-bft": {
                    'title': 'Multi-BFT paper',
                    'subtitle': 'Modified BFT consensus mechanism allowing 1000s of nodes to participate while supporting very high transaction throughput.',
                    'cta': 'View Paper →'
                },
                'edgecloud': {
                    "title": "EdgeCloud Whitepaper",
                    "subtitle": "Introducing Theta Metachain to Power Web3 Businesses.",
                    "cta": "View Paper →"
                },
                "tdrop": {
                    'title': 'TDROP Whitepaper',
                    'subtitle': 'The governance token of ThetaDrop NFT marketplace, powering NFT Liquidity Mining.',
                    'cta': 'View Paper →'
                },
                "mn3": {
                    'title': 'Edge Network 3.0 Whitepaper',
                    'subtitle': 'Cryptoeconomic design of the TFUEL token, including TFUEL burning and staking.',
                    'cta': 'View Paper →'
                },
                "mn2": {
                    'title': 'Blockchain Ledger 2.0 Whitepaper',
                    'subtitle': 'Core features of the Theta blockchain including the Aggregated Signature Gossip Scheme and Resource Oriented Micropayment Pool.',
                    'cta': 'View Paper →'
                },
                "mn4": {
                    'title': 'Metachain 4.0 Whitepaper',
                    'subtitle': 'Introducing Theta Metachain to power Web3 Businesses',
                    'cta': 'View Paper →'
                }
            },
            "common": {
                'learn-more': 'Learn more →',
                'view-article': 'View Article →',
                'view-project': 'View Project →',
                'edge-node-with-line-break': 'Active Edge\nNode',
                'guardian-node-with-line-break': 'Guardian\nNode',
                'validator-node-with-line-break': 'Validator\nNode',
                'edge-node': 'Edge Node',
                'guardian-node': 'Guardian Node',
                'validator-node': 'Validator Node',
                'tfuel': 'TFUEL',
                'download-now': 'Download Now',
            },
            "quotes": {
                "caa": {
                    'body': 'We believe that Web3 and blockchain technologies will bring unprecedented opportunities to our family of storytellers, trendsetters and icons in the entertainment industry. We’ve been impressed with Theta’s achievements since our seed investment in 2016 and look forward to supporting the project in the years ahead.',
                    'name': 'Michael Yanover, Head of Business Development'
                },
                "wpt": {
                    "body": '“World Poker Tour is excited to fully integrate Theta network peer-to-peer streaming infrastructure on our new Theta powered channel on WPT.com and provide millions of our global fans with a superior viewer experience,” said Adam Pliska, CEO of the World Poker Tour. “We view this as a strategic long-term opportunity in the industry and we believe it will contribute to additional user growth.”'
                },
                "cinedigm": {
                    "body": "Cinedigm's senior vice president of products and technology, Tony Huidor, said that the company views the partnership with Theta as a long-term strategic opportunity. It’s a key differentiator in the industry and we believe it will contribute to sustained viewer growth and user engagement in the premium OTT segment."
                },
                "jukin": {
                    "body": "“We’re thrilled to expand our relationship with Theta across multiple fronts with this new partnership,” said Cameron Saless, chief business officer of Trusted Media Brands. “We see value in the Theta Network, their decentralized video infrastructure, and blockchain technology. We are also very excited to partner with them to bring digital collectible products to the FailArmy and The Pet Collective communities.”"
                },
                "american-idol": {
                    "body": "“At American Idol, we are constantly challenging ourselves to be on the forefront of innovation,” says Megan Michaels Wolflick, the series’ Showrunner, and Executive Producer. “With interactive NFT trading cards celebrating our 20th season, fans will be able to immerse themselves even more into the Idol experience, as they collect their favorite contestants’ cards, and follow their progress throughout the competition.”"
                },
                "samsung": {
                    "body": "“Theta's native blockchain operates in the POS proof-of-stake method that minimizes the energy generated in the process of minting NFTs, thereby reducing the burden of destroying the environment after issuing massive amounts of NFTs. As a result of the campaign, 110,000 Samsung Galaxy S22 and 20,000 Galaxy Tabs S8 pre-order customers received NFTs for a total of 130,000 in the Korea market and contributed to Korea's best ever pre-order sales.” -- Shinwoo Lee, Director of Samsung Electronics"
                },
                "price-is-right": {
                    "body": "“For our first The Price Is Right NFTs, we needed to make sure we had the right platform to make the debut a success” said Angela Hueber, Vice President of Commercial and Licensing at Fremantle. “Theta’s low-cost, green NFT platform stood out as the best choice, and their previous sold out NFT drops with some of the most well-known brands and artists reassured us that it was the right home for our legendary series.”"
                },
                "google": {
                    "body": "“We’re impressed by Theta’s achievements in blockchain video & data delivery. We look forward to participating as an enterprise validator, and to providing Google Cloud infrastructure in support of Theta’s long-term mission & future growth.” — Allen Day, Google Cloud Developer advocate"
                },
                "sony": {
                    "body": "“At R&D Center we share Theta Network’s passion towards decentralized technologies, and are excited about the possibilities which Blockchain technologies can offer to creators and can provide effective tools for license and rights management.\" -- Magdalena Wasowska, Head of Sony Europe R&D Center Europe Brussels Laboratory"
                },
                "replay": {
                    "body": "“We’re incredibly excited to remove the monetization friction for content creators in partnership with Theta Labs, who has been a clear visionary in this space. By delivering unparalleled transparency into viewership and performance, we’re excited to reward both the end users and creators powering the content economy.” - Krish Arvapally, CEO, Founder of Replay"
                },
                "opentheta": {
                    "body": "\"At OpenTheta we utilize the Theta blockchain for our NFT marketplace, since we believe in Theta's decentralized, eco-friendly video delivery.\n Through Theta's technologies such as Digital Rights Management (DRM) and the Theta Video API you can unlock videos exclusively with Theta NFTs. These unique features provide a utility only found on the Theta blockchain.\" - Simon Piazolo, founder OpenTheta."
                },
                "passaways": {
                    "body": "As a generative NFT project with a collection of 9,666 videos, the Theta Video API was our first choice. Theta being EVM compatible has made our developer experience frictionless while building our Smart Contracts for $PLASM (TNT20 token) and NFT staking. We look forward to continuing to build our Passaway ecosystem & community on Theta!"
                }
            }
        }
    },
    kr: {
        "translation": {
            "welcome": "React 및 react-i18next에 오신 것을 환영합니다",
            "navbar": {
                "official-links": "공식 링크",
                "enterprises": "기업",
                "video-technology-partners": "AI 기술 파트너",
                "content-and-media-partners": "콘텐츠 및 미디어 파트너",
                "validator-partners": "검증자 파트너",
                "developers": "개발자",
                "resources": "자원",
                "metachain-overview": "메타체인 개요",
                "community": "커뮤니티",
                "theta-edge-network": "Theta 엣지 네트워크",
                "earn-stake-trade": "수익, 스테이킹 및 거래",
                "product-roadmap": "제품 로드맵",
                "explorer": "탐색기",
                "wallet": "지갑",
                "about": "정보",
                "docs": "문서",
                "support": "지원",
                "ecosystem": "생태계",
                "security": "보안"
            },
            "edge-node-cta": {
                "grey-title": "노드 운영자:",
                "title": "AI 및 비디오 작업을 위해 GPU를 공유하여 수익을 창출하세요",
                "subtitle": "Theta Edge Node 다운로드"
            },
            "home": {
                'main-title-tagline': 'AI, 미디어 & 엔터테인먼트를 위한 분산 클라우드',
                "main-title-reinventing": "재창조",
                "main-title-tagline-1": "엣지에서의 AI",
                "main-title-tagline-2": "미래의 통화로서의 GPU",
                "main-title-tagline-3": "DePIN: 자원 공유 경제",
                "main-title-tagline-4": "3D GenAI로 렌더링",
                "main-title-tagline-5": "비디오 Web3 인프라",
                "main-subtitle": "AI, 미디어 및 엔터테인먼트를 위한 분산 클라우드",
                "edgecloud-title": "Theta Elite Booster 노드의 주요 업그레이드가 도착했습니다",
                "edgecloud-launch-date": "2024년 6월 26일",
                "edgecloud-action-button": "AI 개발자 대시보드 방문 ->",
                "mainnet-4-launching": "메인넷 4.0 출시 2023",
                "edgenode-mobile-launch-date": "2024년 9월 25일",
                "theta-video-infrastructure": "Theta 비디오 인프라",
                "recent-news": "최근 뉴스",
                "view-all-news": "모든 뉴스 보기 →",
                "view-more-news": "더 보기 ↓",
                "view-less-news": "덜 보기 ↑",
                "live-nodes": "실시간 노드",
                "hero": {
                    "edgecloud": {
                        "now-available": "이제 사용 가능합니다"
                    },
                    "mobile-edge-node": {
                        "title": "모바일 엣지 노드 출시",
                        'subtitle': 'Android용 모바일 엣지 노드가 이제 사용 가능합니다'
                    }
                }
            },
            "infrastructure": {
                "video-api": {
                    "title": "Web3 비디오 API",
                    "subtitle": "비디오 인코딩, 저장 및 전달 비용 절감"
                },
                "metachain": {
                    "title": "메타체인",
                    "subtitle": "귀하의 Web3 비즈니스를 위한 맞춤형, 고도로 확장 가능한 블록체인"
                },
                "edge-network": {
                    "title": "글로벌 엣지 네트워크",
                    "subtitle": "대역폭, 스토리지 및 컴퓨팅을 공유하여 보상을 받으세요"
                },
                "nft-drm": {
                    "title": "DRM이 포함된 NFT",
                    "subtitle": "콘텐츠 및 지적 재산권을 수익화하고 디지털 권한으로 보호하세요"
                },
                "validators": {
                    "title": "기업 검증자",
                    "subtitle": "글로벌 리더와 함께 Theta 네트워크를 보호하고 관리하세요"
                }
            },
            "enterprises": {
                "vtp-section": {
                    "title": "AI 개발자 및 팀",
                    "subtitle": "Theta EdgeCloud를 사용하여 AI 모델 훈련 및 추론을 수행하는 주요 AI 기업 및 대학에 합류하세요",
                    "point-1": "Theta EdgeCloud는 SSH를 통해 GPU 노드/클러스터에 직접 접근할 수 있는 최상의 가격 대비 성능과 가장 빠른 가입을 제공합니다.",
                    "point-2": "30,000개 이상의 분산된 Nvidia 3090/4090 노드의 성능을 활용하여 추론 및 훈련을 수행하세요.",
                    "point-3": "엔터프라이즈급 Nvidia A100/H100 등의 온디맨드 서비스로 50-70%의 비용 절감.",
                    "cta-1": "ThetaEdgeCloud.com",
                    "cta-2": "Theta EdgeCloud AI 문서 →",
                    "logos": {
                        "vtp": "AI 기술 파트너"
                    }
                },
                "cmp-section": {
                    "title": "콘텐츠 및 미디어 파트너",
                    "subtitle": "광고 지원, 구독 및 유료 시청 수익 외에 Web3 NFT 소유권 및 회원 모델로 확장하세요.",
                    "point-1": "Theta의 NFT 기반 디지털 권한 관리(DRM)로 Web3 및 메타버스 세계로 진입하세요.",
                    "point-2": "팬 참여도와 고객 충성도를 높이기 위해 ThetaDrop의 종합 NFT 플랫폼을 활용하세요.",
                    "point-3": "Theta의 특허 #17/218,245에 기반한 DRM을 탈중앙화된 네트워크에서 구현하세요.",
                    "cta-1": "ThetaDrop.com",
                    "cta-2": "NFT 기반 DRM 문서 →",
                    "logos": {
                        "cmp": "콘텐츠 및 미디어 파트너"
                    }
                },
                "vp-section": {
                    "title": "Theta 검증자 파트너",
                    "subtitle": "세계 최고의 미디어 블록체인을 보호하고 관리하는 글로벌 파트너에 합류하세요.",
                    "point-1": "글로벌 100대 기업에 합류하여 Theta 네트워크를 보호하고 관리하며 탈중앙화하세요.",
                    "point-2": "Web3 및 블록체인 기술의 최전선에 참여하여 미디어 및 엔터테인먼트를 위한 선도적인 퍼블릭 블록체인에 기여하세요.",
                    "point-3": "THETA를 스테이킹하여 TFUEL 보상을 받으세요.",
                    "cta-1": "Theta 검증자 문서",
                    "logos": {
                        "mt": "미디어 및 기술",
                        "bc": "블록체인",
                        "ii": "기관 투자자"
                    }
                }
            },
            "developers": {
                "title": "Theta Network란?",
                "subtitle": "Theta는 AI, 미디어 및 엔터테인먼트를 위한 선도적인 분산 클라우드입니다. [Theta EdgeCloud](https://thetaedgecloud.com/)는 Theta Edge Network에 의해 지원되는 최초의 하이브리드 클라우드-엣지 컴퓨팅 AI 플랫폼으로, 전 세계 30,000개 이상의 분산된 엣지 노드와 80 PetaFLOPS의 항상 사용 가능한 분산 GPU 컴퓨팅 성능을 자랑합니다. Theta는 시장 자본화 기준으로 상위 10위 DePIN 블록체인에 속하며 [Coinmarketcap](https://coinmarketcap.com/view/depin/)과 [Coingecko](https://www.coingecko.com/en/categories/depin)에 상장되어 있으며, [Binance.com](https://www.binance.com/en/markets/coinInfo-AI)의 상위 AI 토큰에 속합니다.\n\n[EdgeCloud Video](https://www.thetaedgecloud.com/dashboard/video/overview)는 비디오 트랜스코딩 및 콘텐츠 전달 비용을 크게 절감할 수 있는 턴키 Web3 비디오 API입니다. Theta의 Web3 인프라에는 [ThetaDrop](https://www.thetadrop.com/) NFT 마켓플레이스 및 [Theta Web3 Theatre](https://www.thetaedgecloud.com/dashboard/video/web3theatre)가 포함되어 있어 엔터테인먼트 기업이 새로운 수익을 창출하고 커뮤니티 참여를 촉진할 수 있습니다.",
                "cta-1": "자세히 알아보기",
                "cards": {
                    "smart-contract": {
                        "title": "스마트 계약 및 DApp 개발",
                        "subtitle": "Remix, Hardhat 및 Truffle Suite를 사용하여 Theta에서 EVM 호환 스마트 계약 및 Dapp을 배포하는 방법을 알아보세요",
                        "cta-title": "문서 보기 →"
                    },
                    "edge-cloud": {
                        "title": "Theta EdgeCloud",
                        "subtitle": "AI, 비디오, 렌더링, 게임 등을 위한 최초의 하이브리드 클라우드-엣지 컴퓨팅 플랫폼.",
                        "cta-title": "플랫폼 보기 →"
                    },
                    "tnt20": {
                        "title": "Theta Network TNT20 토큰 튜토리얼",
                        "subtitle": "몇 분 안에 나만의 TNT-20 토큰을 출시하세요",
                        "cta-title": "튜토리얼 보기 →"
                    },
                    "github": {
                        "title": "Theta Labs Github",
                        "subtitle": "Theta Labs Git 저장소와 함께 오픈 소스 Theta 블록체인에 협력하세요.",
                        "cta-title": "GitHub 보기 →"
                    },
                    "ecosystem": {
                        "title": "Theta 생태계",
                        "subtitle": "Theta 블록체인에서 구축된 Web3 비즈니스, 비디오 플랫폼, NFT 프로젝트 등에 대해 알아보세요.",
                        "cta-title": "프로젝트 보기 →"
                    }
                },
                "products": {
                    "edgecloud": {
                        "description": "Theta EdgeCloud는 AI 팀에게 타의 추종을 불허하는 GPU 가격 대비 성능을 제공합니다. 개발자, 연구원 및 모든 규모의 기업에 AI, 비디오, 렌더링 및 컨테이너화된 작업을 위한 무제한의 비용 효율적인 GPU 처리 접근을 제공합니다. Theta Edge Network를 활용하여 클라우드 컴퓨팅의 장점과 탈중앙화 시스템을 결합합니다.",
                        "sections": {
                            "ai-services": {
                                "title": "EdgeCloud AI 서비스",
                                "description": "인기 있는 생성 AI 모델을 실행하고 몇 번의 클릭 또는 코드 줄만으로 애플리케이션에 통합하세요. Jupyter Notebook과 같은 도구를 사용하여 맞춤형 컨테이너화된 작업을 쉽게 호스팅하고 프로토타입을 생성하세요. AI 모델을 훈련시키기 위해 GPU 노드와 클러스터에 SSH로 쉽게 구성하고 설정하세요.",
                                "cta-title": "AI 문서 보기 →"
                            },
                            "video-services": {
                                "title": "EdgeCloud 비디오 서비스",
                                "description": "비디오 스택 개발 없이 몇 번의 클릭이나 코드 줄만으로 모든 애플리케이션에 탈중앙화된 비디오를 통합하세요. 간단하고 비용 효율적이며 Theta에 의해 지원됩니다.",
                                "cta-title": "비디오 문서 보기 →"
                            }
                        },
                        "cta-title": "Theta EdgeCloud 탐색 →"
                    },
                    "metachain": {
                        "description": "Theta Metachain은 블록체인 네트워크의 상호 연결된 네트워크인 '체인의 체인'입니다. 이는 무제한의 트랜잭션 처리량과 초단위 블록 확정 시간을 달성하기 위해 Theta 블록체인 네트워크의 허가 없는 수평 확장을 가능하게 합니다. 이를 통해 미디어 및 엔터테인먼트 분야의 새로운 Web3 비즈니스를 위한 맞춤형, 고도로 확장 가능한 블록체인이 가능합니다.",
                        "sections": {
                            "subchain": {
                                "description": "수평 확장, 거의 즉각적인 최종성의 서브체인",
                                "cta-title": "서브체인 문서 보기 →"
                            },
                            "video": {
                                "description": "구축할 Web3 비디오 인프라.",
                                "cta-title": "비디오 문서 보기 →"
                            },
                            "token": {
                                "description": "거버넌스 토큰, 사용자 보상 및 유틸리티 소유 - TNT20",
                                "cta-title": "TNT20 가이드 보기 →"
                            }
                        },
                        "cta-title": "메인넷 4.0 백서 →"
                    }
                },
                "logos": {
                    "p": "파트너"
                }
            },
            "ecosystem": {
                "title": "Theta에서 구축됨",
                "subtitle": "Theta 블록체인에서 구축된 Web3 비즈니스, 비디오 플랫폼, NFT 프로젝트 등에 대해 알아보세요."
            },
            "community": {
                "title": "Theta 커뮤니티에 참여하세요",
                "cta-1": "자세히 알아보기",
                "lns-section": "라이브 네트워크 통계",
                "en-section": "Theta 엣지 네트워크",
                "eas-section": "네트워크에 참여 - 수익 및 스테이킹",
                "t-section": "거래",
                "pr-section": "제품 로드맵"
            },
            "join-the-network": {
                "title": "네트워크에 참여하세요"
            },
            "earning": {
                "edge-node": {
                    "kind": "수익",
                    "title": "PC, Mac 및 Linux 박스 리소스를 공유하여 TFUEL을 벌기",
                    "subtitle": "(스토리지, 컴퓨팅 및 대역폭)",
                    "cta": "Theta 엣지 노드"
                },
                "guardian-node": {
                    "kind": "스테이킹",
                    "title": "THETA를 스테이킹하여 Theta Guardian이 되어 TFUEL을 벌기",
                    "subtitle": "Theta 네트워크를 보호하고 참여하세요.",
                    "cta": "Theta Guardian 노드"
                },
                "elite-edge-node": {
                    "kind": "스테이킹",
                    "title": "TFUEL을 스테이킹하여 엘리트 엣지 노드로 업그레이드하여 TFUEL 더 벌기",
                    "subtitle": "8,000명의 엣지 운영자 중 상위 6%에 속하세요.",
                    "cta": "Theta 엣지 노드"
                },
                "tdrop": {
                    "kind": "스테이킹",
                    "title": "TDROP을 스테이킹하여 Theta의 NFT 유동성 채굴 토큰 TDROP 획득",
                    "subtitle": "Theta 지갑에서 간단한 두 단계로 가능합니다.",
                    "cta": "Theta 지갑"
                }
            },
            "roadmap": {
                "col1": {
                    "title": "2024년 상반기",
                    "items": [
                        "EdgeCloud, 차세대 엣지 네트워크, 릴리스 1",
                        [
                            "Theta가 관리하는 클라우드 호스팅 노드",
                            "EEN을 위한 엘리트+ 부스터 기능",
                            "운영 대시보드, 가상 관리",
                            "인기 있는 생성 AI, 비전 및 언어 모델 호스팅"
                        ],
                        "Theta 해커톤 - 메타체인/EdgeCloud/DePIN 초점",
                        "새로운 채택 지표 및 서브체인 통계",
                        "새로운 생태계 프로젝트, 서브체인, TNT20 스테이킹 지원",
                        "Theta 비디오 API 향상",
                        [
                            "라이브 스트림 및 VoD를 위한 스튜디오 수준의 NFT DRM 지원",
                            "고급 메트릭 대시보드"
                        ]
                    ]
                },
                "col2": {
                    "title": "2024년 하반기",
                    "items": [
                        "EdgeCloud, 차세대 엣지 네트워크, 릴리스 2",
                        [
                            "파트너가 자체 클라우드, 데이터 센터에서 호스팅 노드를 설정하고 실행할 수 있도록 지원",
                            "고급 작업 우선순위 설정 및 옵트아웃 지원",
                            "완료된 작업의 요약 보고서/분석",
                            "컴퓨팅 작업을 위한 분산 임시 및 영구 스토리지 지원"
                        ],
                        "DRM, 트랜스코딩 및 전달을 위한 완전히 탈중앙화된 Theta 비디오 API",
                        "ThetaDrop 및 파트너 플랫폼에 통합된 차세대 Theta Web3 극장",
                        "파트너 마켓플레이스로 TDROP 사용 사례 확장"
                    ]
                },
                "col3": {
                    "title": "2025년 이후",
                    "items": [
                        "EdgeCloud, 차세대 엣지 네트워크, 릴리스 3",
                        [
                            "완전히 분산된 엣지 아키텍처에 구축된 최초의 하이브리드 클라우드 컴퓨팅 플랫폼 배포",
                            "작업 및 페이로드 마켓플레이스",
                            "작업 공급 및 수요 최적화",
                            "개발자 API 인터페이스"
                        ]
                    ]
                }
            },
            "wallet": {
                "title": "THETA, TFUEL 및 TDROP 토큰을 안전하고 쉽게 보관하세요",
                "subtitle": "Theta 생태계의 다양한 지갑으로 보내고, 받고, 수익을 올리세요.",
                "platforms-section": {
                    "title": "지원되는 지갑 플랫폼"
                }
            },
            "official-links": {
                "title": "공식 링크",
                "subtitle": "THETA에서 귀하의 보안은 최우선 사항입니다. 사기 및 피싱 시도가 증가하고 있음을 확인하였으며, 저희의 공식 웹사이트를 공유하고자 합니다.\n\n신뢰할 수 있는 사람인 것처럼 가장하여 발신자가 보내는 의심스러운 메시지에 주의하십시오. THETA 팀은 귀하에게 전화하거나, 텍스트 또는 소셜 미디어를 통해 원치 않는 직접 메시지를 보내거나, 귀하의 자금을 외부 지갑 주소로 보내도록 요청하거나, 귀하의 계정 비밀번호 또는 보안 코드를 요청하지 않습니다."
            },
            "docs": {
                "title": "Theta에서 빌드",
                "subtitle": "Theta 블록체인에서 스마트 계약을 시작하고, 노드를 실행하고, Dapp을 개발하는 방법을 알아보세요",
                "papers-section": {
                    "title": "문서 라이브러리"
                },
                "main-docs": {
                    "what-is-theta": {
                        "title": "Theta란?",
                        "subtitle": "차세대 미디어 및 엔터테인먼트 중심 블록체인",
                        "cta": {
                            "title": "문서 보기 →"
                        }
                    },
                    "mn4": {
                        "title": "메인넷 4.0 백서",
                        "subtitle": "Web3 비즈니스를 지원하는 Theta 메타체인 소개",
                        "cta": {
                            "title": "백서 보기 →"
                        }
                    },
                    "edge-cloud": {
                        "title": "EdgeCloud 백서",
                        "subtitle": "AI 컴퓨팅 등의 새로운 시대를 열다.",
                        "cta": {
                            "title": "백서 보기 →"
                        },
                        'cta2': {
                            "title": "문서 보기 →"
                        }
                    }
                }
            },
            "about": {
                "title": "AI, 미디어 및 엔터테인먼트 경험을 바탕으로 안내합니다",
                "subtitle": "Theta 팀은 Salesforce, Netflix, Amazon, Samsung, Vimeo 및 Google의 경험이 풍부한 기술자 및 임원으로 구성되어 있습니다. Theta의 독특한 AI + 블록체인 전문 지식은 AI 및 비디오 팀에게 타의 추종을 불허하는 GPU 가격 대비 성능을 제공하는 열쇠입니다.\n\nTheta는 Gameview Studios(DeNA에 인수) 및 Tapjoy(ironSource에 인수)의 공동 창립자인 CEO Mitch Liu와 MadSkill Game Studios의 공동 창립자인 CTO Jieyi Long이 이끌고 있습니다. 이들은 AI, 블록체인, 비디오, 디지털 권리 관리 등에 대해 [여러 특허](https://patents.google.com/?inventor=jieyi+long&oq=jieyi+long&sort=new)를 보유하고 있습니다.",
                "leadership-team-section": {
                    "title": "리더십 팀"
                },
                "media-advisors-section": {
                    "title": "미디어 고문"
                },
                "blockchain-advisors-section": {
                    "title": "AI 및 블록체인 고문"
                },
                "investors-section": {
                    "title": "투자자"
                }
            },
            "papers": {
                "multi-bft": {
                    "title": "멀티-BFT 논문",
                    "subtitle": "수천 개의 노드가 참여할 수 있도록 하면서 매우 높은 트랜잭션 처리량을 지원하는 수정된 BFT 합의 메커니즘.",
                    "cta": "논문 보기 →"
                },
                "edgecloud": {
                    "title": "EdgeCloud 백서",
                    "subtitle": "Web3 비즈니스를 지원하는 Theta 메타체인 소개.",
                    "cta": "백서 보기 →"
                },
                "tdrop": {
                    "title": "TDROP 백서",
                    "subtitle": "NFT 유동성 채굴을 지원하는 ThetaDrop NFT 마켓플레이스의 거버넌스 토큰.",
                    "cta": "백서 보기 →"
                },
                "mn3": {
                    "title": "엣지 네트워크 3.0 백서",
                    "subtitle": "TFUEL 토큰의 크립토경제 설계, TFUEL 소각 및 스테이킹 포함.",
                    "cta": "백서 보기 →"
                },
                "mn2": {
                    "title": "블록체인 원장 2.0 백서",
                    "subtitle": "집계된 서명 가십 체계 및 자원 지향 마이크로페이먼트 풀을 포함한 Theta 블록체인의 핵심 기능.",
                    "cta": "백서 보기 →"
                },
                "mn4": {
                    "title": "메타체인 4.0 백서",
                    "subtitle": "Web3 비즈니스를 지원하는 Theta 메타체인 소개",
                    "cta": "백서 보기 →"
                }
            },
            "common": {
                "learn-more": "자세히 알아보기 →",
                "view-article": "기사 보기 →",
                "view-project": "프로젝트 보기 →",
                "edge-node-with-line-break": "활성 엣지\n노드",
                "guardian-node-with-line-break": "가디언\n노드",
                "validator-node-with-line-break": "검증자\n노드",
                "edge-node": "엣지 노드",
                "guardian-node": "가디언 노드",
                "validator-node": "검증자 노드",
                "tfuel": "TFUEL",
                'download-now': '지금 다운로드',
            },
            "quotes": {
                // "caa": {
                //     "body": "우리는 Web3 및 블록체인 기술이 엔터테인먼트 산업의 이야기꾼, 트렌드세터 및 아이콘에게 전례 없는 기회를 제공할 것이라고 믿습니다. 2016년 초기 투자 이후 Theta의 성과에 깊은 인상을 받았으며, 앞으로도 이 프로젝트를 지원하게 되어 기쁩니다.",
                //     "name": "Michael Yanover, 사업 개발 책임자"
                // },
                // "wpt": {
                //     "body": "“World Poker Tour는 새로운 Theta 기반 WPT.com 채널에 Theta 네트워크 P2P 스트리밍 인프라를 완전히 통합하게 되어 매우 기쁩니다. 우리는 이것을 업계에서의 전략적 장기적 기회로 보고 있으며, 이는 추가 사용자 성장을 촉진할 것이라고 믿습니다.” — Adam Pliska, World Poker Tour CEO"
                // },
                // "cinedigm": {
                //     "body": "Cinedigm의 제품 및 기술 수석 부사장 Tony Huidor는 Theta와의 파트너십을 장기적인 전략적 기회로 보고 있다고 말했습니다. 이는 업계에서 중요한 차별화 요소이며, 이는 프리미엄 OTT 부문에서 지속적인 시청자 성장 및 사용자 참여에 기여할 것이라고 믿습니다."
                // },
                // "jukin": {
                //     "body": "“우리는 이 새로운 파트너십을 통해 Theta와의 관계를 여러 측면에서 확장하게 되어 기쁩니다.” — Cameron Saless, Trusted Media Brands 최고 사업 책임자. “우리는 Theta 네트워크, 탈중앙화 비디오 인프라 및 블록체인 기술의 가치를 보고 있습니다. 또한 FailArmy 및 The Pet Collective 커뮤니티에 디지털 수집품 제품을 제공하기 위해 그들과 파트너 관계를 맺게 되어 매우 기쁩니다.”"
                // },
                // "american-idol": {
                //     "body": "“American Idol에서는 항상 혁신의 최전선에 서려고 노력합니다.” — Megan Michaels Wolflick, 시리즈 쇼러너 및 총괄 프로듀서. “우리의 첫 The Price Is Right NFT를 위해 성공적인 데뷔를 위해 적절한 플랫폼이 필요했습니다” — Angela Hueber, Fremantle 상업 및 라이선싱 부사장. “Theta의 저비용, 친환경 NFT 플랫폼이 최선의 선택이었으며, 가장 잘 알려진 브랜드 및 아티스트와의 이전에 매진된 NFT 드롭이 우리의 전설적인 시리즈에 적합하다는 것을 확인시켜 주었습니다.”"
                // },
                // "samsung": {
                //     "body": "“Theta의 네이티브 블록체인은 POS 지분 증명 방식을 사용하여 NFT 발행 후 환경 파괴 부담을 줄입니다. 캠페인 결과, 한국 시장에서 130,000명의 고객이 NFT를 받았으며 한국의 사전 주문 판매 최고 기록에 기여했습니다.” — Shinwoo Lee, Samsung Electronics 이사"
                // },
                // "price-is-right": {
                //     "body": "“우리의 첫 The Price Is Right NFT를 위해 성공적인 데뷔를 위해 적절한 플랫폼이 필요했습니다” — Angela Hueber, Fremantle 상업 및 라이선싱 부사장. “Theta의 저비용, 친환경 NFT 플랫폼이 최선의 선택이었으며, 가장 잘 알려진 브랜드 및 아티스트와의 이전에 매진된 NFT 드롭이 우리의 전설적인 시리즈에 적합하다는 것을 확인시켜 주었습니다.”"
                // },
                // "google": {
                //     "body": "“우리는 Theta의 블록체인 비디오 및 데이터 전달 분야의 성과에 깊은 인상을 받았습니다. Theta의 장기적인 목표 및 미래 성장을 지원하기 위해 Google Cloud 인프라를 제공하고 엔터프라이즈 검증자로 참여하게 되어 기대됩니다.” — Allen Day, Google Cloud 개발자 옹호자"
                // },
                // "sony": {
                //     "body": "“R&D 센터에서 우리는 탈중앙화 기술에 대한 Theta Network의 열정을 공유하며, 블록체인 기술이 창작자에게 제공할 수 있는 가능성에 대해 기대하고 있습니다. — Magdalena Wasowska, Sony Europe R&D Center Europe Brussels Laboratory 책임자"
                // },
                // "replay": {
                //     "body": "“우리는 Theta Labs와의 파트너십을 통해 콘텐츠 제작자에 대한 수익 창출 마찰을 제거하게 되어 매우 기쁩니다. 콘텐츠 경제를 활성화하는 사용자와 제작자 모두에게 보상을 제공하게 되어 기대됩니다.” — Krish Arvapally, Replay CEO 겸 창립자"
                // },
                // "opentheta": {
                //     "body": "\"OpenTheta에서는 Theta의 탈중앙화되고 친환경적인 비디오 전달을 위해 Theta 블록체인을 활용하고 있습니다. Theta의 기술(예: 디지털 권리 관리 및 Theta 비디오 API)을 통해 Theta NFT로만 비디오를 독점적으로 잠금 해제할 수 있습니다. 이러한 고유 기능은 Theta 블록체인에서만 제공됩니다.\" — Simon Piazolo, OpenTheta 창립자."
                // },
                // "passaways": {
                //     "body": "9,666개의 비디오를 포함한 생성형 NFT 프로젝트로서 Theta Video API는 우리의 첫 번째 선택이었습니다. Theta의 EVM 호환성 덕분에 Smart Contracts for $PLASM(TNT20 토큰) 및 NFT 스테이킹을 구축하는 동안 개발자 경험이 원활했습니다. Theta에서 우리의 Passaway 생태계 및 커뮤니티를 계속 구축하기를 기대합니다!"
                // }
            }
        }
    }
};


export default translations;