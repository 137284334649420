import React from 'react';
import PageSection from "../components/PageSection";
import HeroContent from "../components/HeroContent";
import {useTranslation} from "react-i18next";

const OfficialLinksPage = () => {
    const { t } = useTranslation();

    return (
        <div className="Page official-links-page">
            <PageSection>
                <HeroContent color={'teal'}
                             category={t('navbar.security')}
                             title={t('official-links.title')}
                             subtitle={t('official-links.subtitle')}
                />

                <h2>Phone Support</h2>
                <p>We do not provide phone support. The best way to reach us is by email, simply
                    click the "submit a request" and send us a message if you can't find what you're
                    looking for on our <a href="https://support.thetadrop.com/hc/en-us">Support Page</a>. &nbsp;</p>
                <p></p>

                <h2>Official THETA Websites</h2>
                <ul>
                    <li>THETA - <a
                        href="https://thetatoken.org/">www.thetatoken.org</a>
                    </li>
                    <li>Theta Docs - <a
                        href="https://docs.thetatoken.org/">docs.thetatoken.org</a>
                    </li>
                    <li>Theta EdgeCloud - <a
                        href="https://www.thetaedgecloud.com">www.thetaedgecloud.com</a>
                    </li>
                    <li>Theta Explorer - <a
                        href="https://explorer.thetatoken.org">explorer.thetatoken.org</a>
                    </li>
                    <li>Theta Video API - <a
                        href="https://www.thetaedgecloud.com/tva">www.thetaedgecloud.com/tva</a>
                    </li>
                    <li>Theta Wallet for iOS, Android, and Web - <a
                        href="https://thetatoken.org/wallet">www.thetatoken.org/wallet</a>
                    </li>
                    <li>Theta Web Wallet - <a
                        href="https://wallet.thetatoken.org/">wallet.thetatoken.org/</a>
                    </li>
                    <li>ThetaDrop - <a
                        href="https://www.thetadrop.com">www.thetadrop.com</a>
                    </li>
                    <li>Support ThetaDrop - <a
                        href="https://support.thetadrop.com/hc/en-us">support.thetadrop.com/hc/en-us</a>
                    </li>
                    <li>Support ThetaNetwork - <a
                        href="https://support.thetanetwork.org/hc/en-us">support.thetanetwork.org/hc/en-us</a>
                    </li>
                    <li>Theta Labs - <a
                        href="https://thetalabs.org">www.thetalabs.org</a>&nbsp;currently
                        redirects to <a
                            href="https://thetatoken.org">www.thetatoken.org</a>&nbsp;
                    </li>
                </ul>
                <p></p>
                <p></p>

                <h2>Official X (Twitter) Accounts </h2>
                <ul>
                    <li>Theta Network - <a
                        href="https://x.com/theta_network">x.com/theta_network</a>&nbsp;
                    </li>
                    <li>ThetaDrop - <a
                        href="https://x.com/ThetaDrop">x.com/ThetaDrop</a>&nbsp;
                    </li>
                </ul>
                <ul>
                    <li>Mitchell Liu - <a
                        href="https://x.com/mitchliu">x.com/mitchliu</a>
                    </li>
                    <li>Jieyi Long - <a
                        href="https://x.com/jieyilong">x.com/jieyilong</a>&nbsp;
                    </li>
                    <li>Wes Levitt - <a
                        href="https://x.com/wes_levitt">x.com/wes_levitt</a>&nbsp;
                    </li>
                    <li>Andrea Berry - <a
                        href="https://x.com/w3Andrea">x.com/w3Andrea</a>
                    </li>
                    <li>Geneon (Community Manager) - <a
                        href="https://x.com/geneon101">x.com/geneon101</a>
                    </li>
                </ul>
                <p></p>

                <h2>Official Instagram Accounts</h2>
                <ul>
                    <li>Theta Network - <a
                        href="https://www.instagram.com/jointhetanetwork">www.instagram.com/jointhetanetwork</a>&nbsp;
                    </li>

                    <li>ThetaDrop - <a
                        href="https://www.instagram.com/thetadropofficial">www.instagram.com/thetadropofficial</a>&nbsp;
                    </li>
                </ul>
                <p></p>

                <h2>Official Medium Accounts</h2>
                <ul>
                    <li>Theta Network - <a
                        href="https://medium.com/theta-network">medium.com/theta-network</a>
                    </li>
                    <li>ThetaDrop - <a
                        href="https://medium.com/thetadrop">medium.com/thetadrop</a>
                    </li>
                </ul>
                <p></p>

                <h2>Official Discord Accounts</h2>
                <ul>
                    <li>Theta Network - <a
                        href="https://discord.com/invite/QfMpeZBfKB">discord.com/invite/QfMpeZBfKB</a>&nbsp;
                    </li>
                </ul>
                <p></p>

                <h2>Official Github Account</h2>
                <ul>
                    <li>Theta Network - <a
                        href="https://github.com/thetatoken">github.com/thetatoken</a>
                    </li>
                </ul>
                <p></p>

                <h2>Official TikTok Account</h2>
                <ul>
                    <li>Theta Network - <a
                        href="https://www.tiktok.com/@theta_network">www.tiktok.com/@theta_network</a>
                    </li>
                </ul>
                <p></p>

                <h2>Official YouTube Channel</h2>
                <ul>
                    <li>Theta Network - <a
                        href="https://www.youtube.com/channel/UCTLBcj0nqZR9lrAhHccoPgQ">www.youtube.com/channel/UCTLBcj0nqZR9lrAhHccoPgQ</a>
                    </li>
                </ul>
                <p></p>

                <h2>Official Linkedin</h2>
                <ul>
                    <li>Theta Network - <a
                        href="https://www.linkedin.com/company/thetanetwork/">www.linkedin.com/company/thetanetwork</a>
                    </li>
                </ul>
                <p></p>
                <p></p>

                <h2>Advice & Best Practices</h2>
                <ul>
                    <li>DO NOT ever store or take a screenshot of your THETA wallet login information (including
                        mnemonic phrases, private key, or the keystore file) with your cell phone/mobile devices, as the
                        picture may be saved into your iCloud and hackers may be able to gain access.
                    </li>
                    <li>Be cautious of unsolicited emails, messages, or links. Verify the authenticity of communications
                        before clicking links or providing information.
                    </li>
                    <li>Prefer end-to-end encrypted messaging apps like Signal, Telegram (secret chats), or WhatsApp for
                        sensitive communications.
                    </li>
                    <li>Be Cautious with Public Wi-Fi: Avoid using public Wi-Fi for sensitive transactions. Use a
                        Virtual Private Network (VPN) to encrypt your internet traffic when using public networks.
                    </li>
                    <li><span>Beware of unsolicited messages from senders who pretend to be someone you trust. The THETA team will</span>
                        <b style={{textDecoration: 'underline'}}>never</b> <span>call you.</span></li>
                </ul>
                <p></p>

                <h2>Reporting Suspicious or Fraudulent Activity</h2>
                <p>If you have suspicious or fraudulent
                    accounts to report, please click <a
                        href="https://support.thetadrop.com/hc/en-us/requests/new">here</a>&nbsp;and
                    submit it to our team.</p><p></p>
            </PageSection>

        </div>
    );
};

export default OfficialLinksPage;
