import {useTranslation} from "react-i18next";
import {TextLinkButton} from "../components/Button";
import SectionTitle from "../components/SectionTitle";
import PageSection from "../components/PageSection";
import HeroContent from "../components/HeroContent";
import {
    DocsWhatIsThetaUrl, ThetaEdgeCloudDocsUrl, WhitepapersEdgeCloudUrl,
    WhitepapersMainnet2Url,
    WhitepapersMainnet3Url,
    WhitepapersMainnet4Url,
    WhitepapersMultiBFTUrl,
    WhitepapersTDROPUrl
} from "../constants";

import edgeCloudImage from '../assets/images/hero/edgecloud@2x.png';
import mn4Image from '../assets/images/metachain/mn4-without-title@2x.png';
import thetaGlowImage from '../assets/images/docs/theta-glow@2x.png';

const DeveloperCard = ({title, subtitle, cta}) => {
    return (
        <div className={'DeveloperCard'}>
            <div className={'DeveloperCard__title'}>
                {title}
            </div>
            <div className={'DeveloperCard__subtitle'}>
                {subtitle}
            </div>
            {
                cta &&
                <TextLinkButton className={'DeveloperCard__cta'}
                                target={'_blank'}
                                href={cta.href}
                >
                    {cta.title}
                </TextLinkButton>
            }
        </div>
    )
};

const MainDocCard = ({image, title, subtitle, cta, cta2, imageStyle}) => {
    return (
        <div className={'MainDocCard'}>
            <img className={'MainDocCard__image'}
                 src={image}
                 style={imageStyle}
            />
            <div className={'MainDocCard__title'}>
                {title}
            </div>
            <div className={'MainDocCard__subtitle'}>
                {subtitle}
            </div>
            <div className={'MainDocCard__ctas'}>
                <TextLinkButton className={'MainDocCard__link'}
                                target={'_blank'}
                                href={cta.href}
                >
                    {cta.title}
                </TextLinkButton>
                {
                    cta2 &&
                    <TextLinkButton className={'MainDocCard__link'}
                                    target={'_blank'}
                                    href={cta2.href}
                    >
                        {cta2.title}
                    </TextLinkButton>
                }
            </div>
        </div>
    )
};


const DocsPage = () => {
    const { t } = useTranslation();

    return (
        <div className={'Page DocsPage'}>
            <PageSection>
                <HeroContent color={'purple'}
                             category={t('navbar.docs')}
                             title={t('docs.title')}
                             subtitle={t('docs.subtitle')}
                />

                <div className={'MainDocCards'}>
                    <MainDocCard title={t('docs.main-docs.what-is-theta.title')}
                                 subtitle={t('docs.main-docs.what-is-theta.subtitle')}
                                 image={thetaGlowImage}
                                 cta={{
                                     title: t('docs.main-docs.what-is-theta.cta.title'),
                                     href: DocsWhatIsThetaUrl
                                 }}
                    />
                    <MainDocCard title={t('docs.main-docs.edge-cloud.title')}
                                 subtitle={t('docs.main-docs.edge-cloud.subtitle')}
                                 image={edgeCloudImage}
                                 cta={{
                                     title: t('docs.main-docs.edge-cloud.cta.title'),
                                     href: WhitepapersEdgeCloudUrl
                                 }}
                                 cta2={{
                                     title: t('docs.main-docs.edge-cloud.cta2.title'),
                                     href: ThetaEdgeCloudDocsUrl
                                 }}
                                 imageStyle={{paddingBottom: 28}}
                    />
                    {/*<MainDocCard title={t('docs.main-docs.mn4.title')}*/}
                    {/*             subtitle={t('docs.main-docs.mn4.subtitle')}*/}
                    {/*             image={mn4Image}*/}
                    {/*             cta={{*/}
                    {/*                 title: t('docs.main-docs.mn4.cta.title'),*/}
                    {/*                 href: WhitepapersMainnet4Url*/}
                    {/*             }}*/}
                    {/*/>*/}
                </div>

                <div className={'DocsPage__papers-section'}>
                    <SectionTitle title={t('docs.papers-section.title')}/>
                    <div className={'DeveloperCards'}>
                        <DeveloperCard title={t('papers.edgecloud.title')}
                                       subtitle={t('papers.edgecloud.subtitle')}
                                       cta={{
                                           title: t('papers.edgecloud.cta'),
                                           href: WhitepapersEdgeCloudUrl
                                       }}
                        />
                        <DeveloperCard title={t('papers.mn4.title')}
                                       subtitle={t('papers.mn4.subtitle')}
                                       cta={{
                                           title: t('papers.mn4.cta'),
                                           href: WhitepapersMainnet4Url
                                       }}
                        />
                        <DeveloperCard title={t('papers.mn3.title')}
                                       subtitle={t('papers.mn3.subtitle')}
                                       cta={{
                                           title: t('papers.mn3.cta'),
                                           href: WhitepapersMainnet3Url
                                       }}
                        />
                        <DeveloperCard title={t('papers.mn2.title')}
                                       subtitle={t('papers.mn2.subtitle')}
                                       cta={{
                                           title: t('papers.mn2.cta'),
                                           href: WhitepapersMainnet2Url
                                       }}
                        />
                        <DeveloperCard title={t('papers.multi-bft.title')}
                                       subtitle={t('papers.multi-bft.subtitle')}
                                       cta={{
                                           title: t('papers.multi-bft.cta'),
                                           href: WhitepapersMultiBFTUrl
                                       }}
                        />
                        <DeveloperCard title={t('papers.tdrop.title')}
                                       subtitle={t('papers.tdrop.subtitle')}
                                       cta={{
                                           title: t('papers.tdrop.cta'),
                                           href: WhitepapersTDROPUrl
                                       }}
                        />
                    </div>
                </div>
            </PageSection>
        </div>
    )
};

export default DocsPage;