export const WhitepapersMultiBFTUrl = 'https://github.com/thetatoken/theta-protocol-ledger/blob/master/docs/multi-level-bft-tech-report.pdf';
export const WhitepapersMainnet2Url = 'https://assets.thetatoken.org/Theta-white-paper-latest.pdf';
export const WhitepapersMainnet3Url = 'https://assets.thetatoken.org/Theta-white-paper-3-0-latest.pdf';
export const WhitepapersMainnet4Url = 'https://assets.thetatoken.org/theta-mainnet-4-whitepaper.pdf';
export const WhitepapersTDROPUrl = 'https://assets.thetatoken.org/Theta-Ecosystem-2022-and-TDROP-Whitepaper.pdf?v=1659657576.891';
export const WhitepapersEdgeCloudUrl = 'https://assets.thetatoken.org/theta-edgecloud-whitepaper-latest.pdf?v=1659657576.891';
export const EdgeCloudAIShowcaseUrl = 'https://www.thetaedgecloud.com/ai-showcase';
export const EdgeCloudAIModelExplorerUrl = 'https://www.thetaedgecloud.com/dashboard/ai/service/model-explorer';
export const EdgeCloudUrl = 'https://www.thetaedgecloud.com';

export const MetachainLaunchDate = new Date('Dec 1, 2022 15:00:00 PST');

export const EdgeCloudLaunchDate = new Date('May 1, 2024 15:00:00 PST');
export const EliteBoosterLaunchDate = new Date('Jun 26, 2024 15:00:00 PST');
export const MobileEdgeNodeLaunchDate = new Date('Sept 25, 2024 15:00:00 PST');

export const ThetaVideoAPIUrl = 'https://www.thetavideoapi.com';
export const ThetaEdgeCloudUrl = 'https://www.thetaedgecloud.com';
export const ThetaEdgeCloudDocsUrl = 'https://docs.thetatoken.org/docs/theta-edgecloud-overview ';

export const ThetaDropUrl = 'https://www.thetadrop.com';

export const DocsWhatIsThetaUrl = 'https://docs.thetatoken.org/docs/what-is-theta-network';
export const DocsHomeUrl = 'https://docs.thetatoken.org';
export const DocsThetaVideoAPIUrl = 'https://docs.thetatoken.org/docs/theta-video-api-overview';
export const DocsNFTDRMUrl = 'https://docs.thetatoken.org/docs/theta-nft-based-drm';
export const DocsValidatorUrl = 'https://docs.thetatoken.org/docs/running-a-theta-validator';
export const DocsTNT20TutorialUrl = 'https://docs.thetatoken.org/docs/theta-blockchain-tnt20-token-integration-guide';
export const DocsSmartContractsUrl = 'https://docs.thetatoken.org/docs/turing-complete-smart-contract-support';
export const DocsGuardianNodeUrl = 'https://docs.thetatoken.org/docs/guardian-node-overview';
export const DocsEdgeNodeUrl = 'https://docs.thetatoken.org/docs/setup-theta-edge-node';
export const DocsEliteEdgeNodeUrl = 'https://docs.thetatoken.org/docs/elite-edge-node-staking-process';
export const DocsTDROPUrl = 'https://docs.thetatoken.org/docs/intro-to-tdrop';

export const DocsEdgeCloudServicesAI = 'https://docs.thetatoken.org/docs/theta-edgecloud-ai-services';
export const DocsEdgeCloudServicesVideo = 'https://docs.thetatoken.org/docs/theta-video-api-overview';
export const DocsEdgeCloudServicesRendering = 'https://docs.thetatoken.org/docs/theta-edgecloud-overview';

export const MediumUrl = 'https://medium.com/theta-network';
export const TwitterUrl = 'https://twitter.com/Theta_Network';
export const FacebookUrl = 'https://www.facebook.com/ThetaNetwork/';
export const GithubUrl = 'https://github.com/thetatoken';
export const DiscordUrl = 'https://discord.gg/QfMpeZBfKB';

export const ExchangesBinanceUrl = 'https://www.binance.com/en/trade/THETA_USDT';
export const ExchangesBinanceUSUrl = 'https://www.binance.us/en/trade/THETA_USDT';
export const ExchangesHuobiUrl = 'https://www.huobi.com/en-us/exchange/theta_usdt';
export const ExchangesOKXUrl = 'https://www.okx.com/trade-spot/theta-usdt';
export const ExchangesGateUrl = 'https://gate.io/trade/THETA_USDT';
export const ExchangesBithumbUrl = 'https://www.bithumb.com/trade/order/THETA_KRW';
export const ExchangesUpbitUrl = 'https://upbit.com/exchange?code=CRIX.UPBIT.KRW-THETA';
export const ExchangesCryptoComUrl = 'https://crypto.com/exchange/trade/spot/THETA_USDT';
export const ExchangesThetaSwapUrl = 'https://swap.thetatoken.org/swap';
export const ExchangesVoltSwapUrl = 'https://info.voltswap.finance/#/home?network=theta';
export const ExchangesKorbitUrl = 'https://lightning.korbit.co.kr/m/markets/market/?market=theta-krw';
export const ExchangesKucoinUrl = 'https://www.kucoin.com/trade/THETA-USDT';
export const ExchangesBitfinexUrl = 'https://www.bitfinex.com/t/THETA:USDT';
export const ExchangesTokoUrl = 'https://www.tokocrypto.com/en/trade/THETA_USDT';
export const ExchangesIndodaxUrl = 'https://indodax.com/market/THETAIDR';


export const DownloadsENWindowsUrl = 'https://marketing-api.thetatoken.org/downloads/edge-node/windows';
export const DownloadsENMacOSUrl = 'https://marketing-api.thetatoken.org/downloads/edge-node/macos';
export const DownloadsENLinuxUrl = 'https://docs.thetatoken.org/docs/theta-edge-node-run-with-docker';
export const DownloadsENAndroidUrl = 'https://play.google.com/store/apps/details?id=org.theta.edge_node_mobile';

export const DownloadsGNWindowsUrl = 'https://marketing-api.thetatoken.org/downloads/guardian-node/windows';
export const DownloadsGNMacOSUrl = 'https://marketing-api.thetatoken.org/downloads/guardian-node/macos';
export const DownloadsGNLinuxUrl = 'https://marketing-api.thetatoken.org/downloads/guardian-node/linux';
export const DownloadsGNCLIUrl = 'https://github.com/thetatoken/guardian-mainnet-guide/blob/master/docs/CLI.md#running-a-guardian-node-through-command-line';

export const WalletsWebUrl = 'https://wallet.thetatoken.org';
export const WalletsIOSUrl = 'https://itunes.apple.com/app/theta-wallet/id1451094550?mt=8';
export const WalletsAndroidUrl = 'https://play.google.com/store/apps/details?id=org.theta.wallet';
export const WalletsChromeUrl = 'https://chrome.google.com/webstore/detail/theta-wallet/ckelpdlfgochnkdgikcgbimdcfgpkhgk?hl=en-US';
export const WalletsMetamaskUrl = 'https://metamask.io/';